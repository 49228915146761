import React from 'react';
import PropTypes from 'prop-types';
import './HeroRightImage.css';

const HeroRightImage = ({ src, alt }) => {
    return (
        <div>
            <div className='hero-right-image-box'>
                <img className='hero-right-image' src={src} alt={alt}/>
            </div>
        </div>
    );
};

HeroRightImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

HeroRightImage.defaultProps = {
    alt: 'Banner page Image',
};

export default HeroRightImage;
