import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/jurisdictions/united-arab-Emirates/hero-img.png'
import PageBanner from '../../../../Assets/images/jurisdictions/united-arab-Emirates/page-banner.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'UAE',
    link: '/uae',
  },
]

function UAE() {
  return (
      <main className='main'>
        <section className='section'>
          <BreadCrumbs data={BreadCrumbsData}/>

          <div className='container'>

            <h1 className='section-title'>United Arab Emirates</h1>

            <div className='grid-container'>
              <div className='flex-start'>
                <h2 className='page-title'>Establishing Excellence in Offshore Services</h2>
                <p className='page-paragraph'>
                  UAE, a global hub strategically located at the crossroads of Europe,
                  Asia, and Africa, has emerged as a premier destination for offshore
                  services, investment opportunities, and residency solutions.
                  Governed by progressive regulatory authorities, Dubai offers a
                  business-friendly environment with a robust legal framework, making
                  it an ideal location for various financial and corporate services.
                </p>
                <p className='page-paragraph'>
                  At Offshore Advisory, we specialize in navigating the intricacies of
                  UAE’s regulatory landscape. Our comprehensive suite of services is
                  designed to facilitate seamless business operations, ensure
                  compliance with local regulations, and optimize your investment
                  strategies.
                </p>
              </div>
              
              <HeroRightImage src={HeroImage} />
            </div>

            <BannerPageImg src={PageBanner} />
            
            <div className='flex-start'>
              <h3 className='page-subtitle'>
                We are dedicated to providing expert guidance and support for the
                following services:
              </h3>
              <h3 className='page-title'>Banking Licenses</h3>
              <p className='page-paragraph'>
                Seamlessly obtain licenses to operate banking services in Dubai,
                leveraging the emirate’s financial stability and regulatory support.
                Our team assists with the entire licensing process, from application
                preparation to post-approval compliance, ensuring that your banking
                operations are set up for success in this strategic location.
              </p>
              <h3 className='page-title'>Insurance Licenses</h3>
              <p className='page-paragraph'>
                Safeguard your ventures with comprehensive insurance coverage. We
                facilitate the acquisition of insurance licenses for businesses
                operating in Dubai, ensuring that you meet all regulatory requirements
                and can offer a full range of insurance products to your clients. Our
                expertise covers various types of insurance licenses, including life,
                health, and property insurance.
              </p>
              <h3 className='page-title'>IBC Formation</h3>
              <p className='page-paragraph'>
                Streamline your global business structure with our International
                Business Company (IBC) formation services. We ensure a robust presence
                in Dubai, providing you with the legal and regulatory framework needed
                to operate efficiently and effectively. Our IBC services include
                company registration, compliance management, and ongoing
                administrative support.
              </p>
              <h3 className='page-title'>Brokerage Licenses</h3>
              <p className='page-paragraph'>
                Gain regulatory approvals for your financial brokerage operations,
                positioning your business for success in the dynamic financial markets
                of Dubai. Our services include application preparation, submission,
                and liaison with regulatory authorities, ensuring that your brokerage
                firm meets all necessary requirements and can operate with confidence.
              </p>
              <h3 className='page-title'>Trust Licenses</h3>
              <p className='page-paragraph'>
                Establish and operate trusts with ease. Our services enable businesses
                to acquire trust licenses, ensuring asset protection and management.
                We provide comprehensive support for trust formation, administration,
                and compliance, helping you manage and protect client assets
                effectively.
              </p>
            </div>

            <div className='flex-start'>
              <h2 className='page-subtitle'>Residency and Citizenship</h2>
              <h3 className='page-title'>Residency in Dubai</h3>
              <p className='page-paragraph'>
                Secure your residency in UAE through various investment and business
                opportunities. Dubai offers a range of residency options, including
                investor visas, entrepreneur visas, and property owner visas. Our team
                guides you through the application process, ensuring you meet all the
                requirements and benefit from the residency privileges.
              </p>
              <h3 className='page-title'>Citizenship and Passport</h3>
              <p className='page-paragraph'>
                While obtaining citizenship in UAE is more complex, our experts
                provide advisory services on acquiring residency and exploring other
                citizenship-by-investment programs globally. We offer strategic advice
                to help you achieve your long-term residency and citizenship goals.
              </p>
            </div>

            <div className='flex-start'>
              <h2 className='page-subtitle'>Investment Opportunities</h2>
              <h3 className='page-title'>Real Estate Investment</h3>
              <p className='page-paragraph'>
                Dubai’s real estate market is one of the most lucrative in the world.
                Invest in premium properties, from residential to commercial, and
                benefit from high returns and capital appreciation. Our team provides
                comprehensive support, from property selection to transaction
                completion.
              </p>
              <h3 className='page-title'>Business Investment</h3>
              <p className='page-paragraph'>
                Dubai offers numerous opportunities for business investments,
                including setting up free zone companies, mainland companies, and
                offshore entities. Benefit from Dubai’s tax advantages, strategic
                location, and business-friendly policies. We assist in identifying the
                best investment opportunities and navigating the regulatory
                requirements.
              </p>
              <h3 className='page-title'>Financial Investment</h3>
              <p className='page-paragraph'>
                Explore diverse financial investment options in Dubai, including
                stocks, bonds, mutual funds, and other financial instruments. Our
                experts provide tailored investment strategies to help you maximize
                returns and achieve your financial goals.
              </p>
            </div>

            <div className='flex-start'>
              <h2 className='page-subtitle'>Benefits of Choosing UAE</h2>
              <h3 className='page-title'>Regulatory Advantages</h3>
              <p className='page-paragraph'>
                The regulatory landscape of Dubai is designed to accommodate
                international businesses, providing a secure and compliant platform
                for financial operations. The emirate’s regulatory authorities ensure
                that regulations are clear, consistent, and conducive to business
                growth, allowing you to operate with confidence.
              </p>
              <h3 className='page-title'>Tax Efficiency</h3>
              <p className='page-paragraph'>
                Enjoy favorable tax structures that enhance financial efficiency and
                profitability, making Dubai an attractive choice for businesses
                seeking tax optimization. The jurisdiction offers various tax
                incentives and benefits, enabling you to maximize your financial
                performance and reinvest savings into your business.
              </p>
              <h3 className='page-title'>Global Accessibility</h3>
              <p className='page-paragraph'>
                Strategically located, Dubai serves as a gateway to Europe, Asia, and
                Africa, facilitating international business expansion. The emirate’s
                strategic location provides unparalleled access to key markets,
                enhancing your business's reach and growth potential.
              </p>
              <h3 className='page-title'>Legal Stability</h3>
              <p className='page-paragraph'>
                Benefit from a stable legal environment, ensuring a secure foundation
                for your business operations and financial transactions. Dubai’s legal
                framework is designed to protect businesses and investors, providing
                peace of mind and a reliable basis for long-term planning.
              </p>
              <h3 className='page-title'>Diverse Licensing and Investment Options</h3>
              <p className='page-paragraph'>
                Dubai offers a range of licenses, including banking, brokerage, IBC,
                insurance, and trust licenses, as well as diverse investment
                opportunities in real estate, business, and financial markets. This
                diversity allows you to choose the best fit for your business model
                and growth strategy.
              </p>
            </div>

          </div>
        </section>
      </main>
  )
}

export default UAE
