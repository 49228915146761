import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/services/gaming-gambling-western-sahara/hero-image.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Gaming and Gambling In Western Sahara',
    link: '/gaming-and-gambling-western-sahara',
  },
]

function GamingGamblingWesternSahara() {
  return (
    <main className='main'>
      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />

        <div className='container'>
          <h1 className='section-title'>Gaming and Gambling license in Western Sahara</h1>

          <div className='grid-container'>
            <div>
              <p className='body-pargraph'>
                Western Sahara provides different banking and financial services
                in the jurisdiction of SADR, including eGambling license. There
                are two categories of eGambling license:
              </p>
              <p className='body-pargraph'>
                License authorizes the organization and preparation of gambling
                operations, namely the registration and verification of players,
                the contractual relationship with them, and the management of
                player funds.
              </p>
              <p className='body-pargraph'>
                License authorizes the effecting of the gambling transaction
                including operational management of a gambling platform located
                within an approved hosting center.
              </p>
              <p className='body-pargraph'>
                Depending on their proposed operations, an organization may
                apply for one or both types of license.
              </p>
            </div>
            <HeroRightImage src={HeroImage} />
          </div>

          <div>
            <h3 className='page-title'>Time frame</h3>
            <p className='page-subtitle'>1 day</p>
            <p className='page-paragraph'>Booking the name of the company-1 day</p>

            <p className='page-subtitle'>2-3 weeks</p>
            <p className='page-paragraph'>
              eGambling license approval upon receipt of application fee,
              approved application form and KYC documents (scans ok to
              initiate)-2-3 weeks
            </p>

            <p className='page-title'>2 days</p>
            <p className='page-paragraph'>
              When the funds are received and confirmed, the CRA of SADR may
              take 2 days to register and notarise the license.
            </p>

            <h2 className='container-title'>FAQ about eGambling license</h2>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                  Are there other costs to be expected?
                  </span>
                  <br/>
                  No it takes 3 weeks to create the company and obtain the online
                  gambling license
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    Do we need to provide proof of accounting?
                  </span>
                  <br/>
                  Not applicable
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    What are the tax rules in Western Sahara? Are there any taxes?
                  </span>
                  <br/>
                  No taxes
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    Should we need a company secretary?
                  </span>
                  <br/>
                  No
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    is there a legal minimum share capital?
                  </span>
                  <br/>
                  The minimum capital is USD 100,000.00
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    Should the company open a bank account in a western saharian bank?
                  </span>
                  <br/>
                  You need to open a bank account outside of Western Sahara.
                </p>
              </li>
            </ul>
            <br/>
            <h3 className='page-title'>Application form and documents</h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Signed application form;
                </p>
              </li>
              
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Personal info including name, address, phone number and email of
                  all directors/shareholders;
                </p>
              </li>
              
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Bank referense;
                </p>
              </li>
              
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Business reference;
                </p>
              </li>
              
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  ID of all directors/shareholders;
                </p>
              </li>
            </ul>
              
              <p className='page-paragraph'>
                It is required for the business owner, the Board member, the real
                beneficiary or the proprietar to submit a statement from the
                penalty register in one's country of origin or an equivalent
                document issued by a judicial or administrative authority
                confirming the absence of penalties for crimes committed against
                government authorities, money laundering crimes or another
                deliberate crimes no earlier than three months ago and certified
                by a notary or in another similar way and legalised or approved by
                the certificate replacing legalisation (Apostille), unless
                otherwise provided by an international treaty.
              </p>
          </div>
        </div>
      </section>
    </main>
)
}

export default GamingGamblingWesternSahara
