import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import PageBanner from '../../../../Assets/images/area/non-dom-tax-regim/page-banner.png'
import HeroImage from '../../../../Assets/images/area/non-dom-tax-regim/hero-img.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Non Dom Tax Regime',
    link: '/non-dom-tax-regime',
  },
]

function NonDomTaxRegime() {
  return (
      <main className='main'>

        <section className=' section  asset-protection-section'>
          <BreadCrumbs data={BreadCrumbsData}/>

          <div className='container'>
            <h1 className='section-title'>Non Dom Tax Regime</h1>

            <div className='grid-container'>
              <p className='body-pargraph'>
                  Article 24 bis of the Italian Income Tax Code (1) was introduced by the 2017 Italian Budget Law (2)
                  and marks the beginning of a favourable tax regime for new residents in Italy (the “Non-Dom Regime”).
                  It is inspired by the British resident non-domiciled regime as well as by regimes in other EU
                  countries, such as Portugal and Malta.
                <br/>
                  This measure is part of a set of rules aiming to attract foreign taxpayers to Italy. Other measures
                  include a proposed simplified procedure to obtain an Italian visa for non-EU individuals who can make
                  substantial investments in the country (e.g. start ups, R&D, etc.) and reduced rates of income tax for
                  highly skilled Italian citizens moving back to Italy from abroad.
                </p>

                <HeroRightImage src={HeroImage} />
              </div>

                <BannerPageImg src={PageBanner} />

              <div className='flex-start'>
                <h3 className='page-title'>
                  THE NEW TAX REGIME
                </h3>

                <p className='body-pargraph'>
                  The Non-Dom Regime aims to reduce the effect of the Italian worldwide tax principle for eligible new
                  Italian tax residents by introducing a substitute flat tax on their foreign-sourced income for
                  individual income tax purposes.
                  Eligible taxpayers can choose the country or countries where the foreign income has been realised
                  subject to the Non-Dom Regime (the so-called “cherry picking” principle) (3). Any income generated in
                  the countries excluded from the Non-Dom Regime will be subjected to ordinary income tax and benefit
                  from tax relief on taxes paid abroad (under ordinary limitations) and from relevant tax treaty
                  protection (if any).
                </p>

                <p className='body-pargraph'>
                  In addition, the new regime sets out (4) an exemption from certain report obligations (5) and wealth
                  taxes (6).
                  Eligible taxpayers who opt for the Non-Dom Regime apply a substitute tax on foreign-sourced income
                  amounting to a yearly lump sum payment of €100,000 (with an additional €25,000 for each family member
                  to which the Non-Dom Regime may be extended). A tax credit is not available for taxes paid abroad on
                  foreign- sourced income that is included in the Non-Dom Regime.
                </p>

                <p className='body-pargraph'>
                  Notably, capital gain from the disposal of qualifying shareholding (7) is excluded from the Non-Dom
                  Regime and ordinary taxation therefore applies, if the capital gain is realised in the first 5 years
                  following the exercise of the option.
                </p>

                <p className='page-paragraph'>
                  Finally, the regime provides for an exemption from donations and inheritance tax related to assets
                  held outside of Italy
                </p>

              </div>
              <div className='flex-start'>
                <h3 className='page-title'>
                  ELIGIBILITY CRITERIA
                </h3>

                <ul className='page-list margin-bottom'>
                  <li className='page-list-item'>
                    <div className='page-list-icon-box'>
                      <FaDiamond className='list-diamon-icon'/>
                    </div>
                    <p className='page-list-paragraph'>
                      New tax residents can exercise the option for the Non-Dom Regime if they:
                    </p>
                  </li>
                  <li className='page-list-item'>
                    <div className='page-list-icon-box'>
                      <FaDiamond className='list-diamon-icon'/>
                    </div>
                    <p className='page-list-paragraph'>
                      transfer their tax residence to Italy;
                    </p>
                  </li>
                  <li className='page-list-item'>
                    <div className='page-list-icon-box'>
                      <FaDiamond className='list-diamon-icon'/>
                    </div>
                    <p className='page-list-paragraph'>
                      have not been resident for tax purposes in Italy (8) for at least 9 out of the 10 tax periods
                      preceding that in which the option is exercised; obtain the approval of the Italian tax
                      authorities
                      following an advanced ruling procedure.
                    </p>
                  </li>
                </ul>
              </div>

            <div className='flex-start'>
                <h3 className='page-title'>
                  ADVANCE TAX RULING AND OPTION FOR THE REGIME
                </h3>

                <p className='page-paragraph'>
                  The option for the Non-Dom Regime is exercised within the term for the submission of the annual tax
                  return for the tax period in which the eligible taxpayer becomes an Italian tax resident, subject to
                  the previous approval by the Italian tax authorities.
                  <br/>
                  To obtain this approval, the taxpayers have to apply for a tax ruling with the Italian tax authorities
                  and show that the eligibility criteria are satisfied (9). The application can also include approval
                  requests of the Non-Dom Regime for family members who meet the eligible criteria. The tax authorities
                  have 120 days to reply to a tax ruling request, but this may be extended by 60 days in certain
                  circumstances. The timing of the application is therefore crucial to enable the taxpayer to properly
                  exercise the option.
                  Please note that the applicative provisions, necessary to implement the tax regime, should be issued
                  within 90 days from entering into force of the Italian Budget Law (10).
                </p>
              </div>

              <div className='flex-start'>
                <h3 className='page-title'>
                  DURATION AND TIMEFRAME
                </h3>

                <p className='page-paragraph'>
                  The Non-Dom Regime applies for a 15-year period after the option has been exercised. However, the
                  taxpayer can revoke the option at any time. In case the tax flat amount is not duly paid, the regime
                  is repealed starting from the relevant tax period (i.e. the previous application of Non-Dom Regime is
                  not effected). Revocation and disqualification preclude the exercise of a new option.
                  <br/>
                  The Non-Dom Regime applies from the 2017 tax period (the relevant tax return will be submitted in
                  2018) (11).
                </p>
              </div>

              <div className='flex-start'>
                <h3 className='page-title'>
                  OTHER CONSIDERATIONS
                </h3>
                <p className='body-pargraph'>
                  The option for the Italian Non-Dom Regime cannot be combined with other favourable regimes (12), such as the one provided for the repatriation of scientists and researchers and the detaxation of both employment and self-employment income for new residents in Italy.
                </p>
              </div>

            </div>

        </section>

      </main>
  )
}

export default NonDomTaxRegime
