import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/services/brokerage-forex-anjouan/hero-image.png'
import {FaDiamond} from "react-icons/fa6";

import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaMinus} from "react-icons/fa6";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Brokerage And Forex License in Anjouan',
    link: '/brokerage-and-forex-anjouan',
  },
]

function BrokerageForexAnjouan() {
  return (
    <main className='main'>

      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />

        <div className='container'>
          <h1 className='section-title'>Brokerage and Forex license in Anjouan</h1>
          
          <div className='grid-container'>
            <div className='flex-start'>
              <p className='body-pargraph'>
                If you aim to establish a brokerage or forex business, we offer
                comprehensive support to navigate the licensing process with
                ease. Our expertise ensures a seamless transition from
                application to operation, focusing on regulatory compliance and
                operational efficiency. Our services encompass all aspects of
                brokerage and forex license acquisition, providing you with a
                strong foundation for success.
              </p>
              <h3 className='page-subtitle'>Why Obtain a Brokerage and Forex License in Anjouan?</h3>
              <h3 className='page-title'>Regulatory Flexibility</h3>
              <p className='body-pargraph'>
                Offshore jurisdictions often have streamlined regulatory
                frameworks, making it easier to obtain and maintain a brokerage
                or forex license compared to more stringent onshore
                jurisdictions. This flexibility allows for quicker setup and the
                ability to adapt swiftly to changing market conditions. Offshore
                regulatory bodies are typically more open to innovative
                financial products and services, fostering growth and
                innovation.
              </p>
              <h3 className='page-title'>Tax Optimization</h3>
              <p className='body-pargraph'>
                Offshore brokerage and forex firms may benefit from favorable
                tax regimes, allowing for greater efficiency in tax planning and
                optimization. These jurisdictions often offer lower tax rates or
                even tax exemptions on certain types of income, enabling your
                business to achieve significant cost savings. By strategically
                planning your tax obligations, you can enhance profitability and
                reinvest savings into expanding your services and client base.
              </p>
              <h3 className='page-title'>Confidentiality</h3>
              <p className='body-pargraph'>
                Many offshore jurisdictions prioritize client confidentiality,
                providing a secure environment for trading operations and client
                transactions. This high level of privacy helps protect client
                information from unauthorized access and potential threats. The
                commitment to confidentiality fosters trust and reliability,
                attracting clients who value discretion and security in their
                financial dealings.
              </p>
              <h3 className='page-title'>Global Reach</h3>
              <p className='body-pargraph'>
                By establishing an offshore brokerage or forex firm, you gain
                access to international markets and can cater to clients from
                around the world. This global reach enhances your business
                opportunities and revenue potential, positioning your firm as a
                key player in the international financial landscape. Offshore
                firms can offer services in multiple currencies and facilitate
                cross-border transactions with ease, expanding your customer
                base and service offerings.
              </p>
            </div>
            <HeroRightImage src={HeroImage} />
          </div>

          <div className='body-content'>
            <h3 className='page-title'>Formation</h3>
            <p className='body-pargraph'>
              Forming a brokerage is a relatively quick process due to the lack
              of bureaucracy. Approval can be granted swiftly if the necessary
              requirements are met. There are no requirements for local offices
              or staff, although we would suggest that all companies have this
              facility which can be arranged at a minimal cost ( see Local
              office facility ). Application for permission for a Brokerage
              license should be made to Application Form License
            </p>
            <h3 className='page-subtitle'>The following documents will be required:</h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  The percentage of ownership by the shareholders
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  The details of the ultimate beneficial owners (chart may help if
                  many)
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Any other useful information to support the application.
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  CVs of all the directors
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Full contact details of the directors and shareholders (KYC
                  questionnaire)
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Bank reference for all the beneficial owners
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Clear certified passport copies of all beneficial owners
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Proof of addresses (utility bills)
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Business or legal reference for the beneficial owners
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Legal doc for the shareholding companies (articles, certificate of
                  incorporation etc)
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Latest audited financial statements for all shareholding companies
                  (if any)
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  A certificate to prove that the beneficial owners do not have a
                  criminal record
                </p>
              </li>
            </ul>

            <br/>
            <h3 className='page-title'>KYC AND DUE DILIGENCE</h3>
            <p className='body-pargraph'>
              A charge is made for each director / shareholder for due diligence
              (cost is dependent of country of residence ), in the event that a
              client is not accepted for a license this payment is not
              refundable
            </p>
            <p className='page-paragraph'>
              Please submit the following documents for each of the beneficial
              owner, director, manager, authorised signatory, shareholder/member
              of the Entity or agent under power of attorney.
            </p>
            <h3 className='page-subtitle'>(a) For Individual</h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  A certified copy of passport or current driving license or
                  national identity card showing the name, date and place of birth,
                  nationality, signature of holder and data of issue/expiry. The
                  document must be certified by a banker, lawyer or actuary or
                  notary or accountant holding a recognized professional
                  qualification
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph page-paragraph'>
                  Proof of residential address. Acceptable evidence includes an
                  original utility bill, bank or credit card statement.Original Bank
                  Reference.
                </p>
              </li>
            </ul>
            
            <h3 className='page-subtitle'>(b) For Corporate/Partnership</h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Certificate of incorporation or registration and the memorandum
                  and articles of association or constitution or deed of partnership
                  or Act de Soci?t? as the case may be. Document has to be certified
                  by banker, lawyer or actuary or notary or accountant holding a
                  recognized professional qualification.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Original Bank Reference.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  A certified true copy of latest audited account or annual report.
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  A Certificate of good standing.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  List of substantial shareholders (who holds not less than 5% of
                  the voting power exercisable at the meeting of shareholder/member)
                  and a list of directors, and corporate structure.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaMinus className='list-minus-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  A certified copy of Board resolution granting authority to its
                  managers, officers or employees to transact business on its
                  behalf.
                </p>
              </li>
            </ul>
            
          </div>
        </div>
      </section>
    </main>
)
}

export default BrokerageForexAnjouan
