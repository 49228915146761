import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Hero from "./components/Pages/Home/Hero/Hero";
import About from "./components/Pages/About Company/About/About";
import FAQ from "./components/Pages/About Company/FAQ/FAQ";
import OffshoreCompanyRegistration from "./components/Pages/Area/Offshore Company Registration/OffshoreCompanyRegistration";
import License from "./components/Pages/Area/License/License";
import OpeningBankAccounts from "./components/Pages/Area/Opening Bank Accounts/OpeningBankAccounts";
import ResidencyPassport from "./components/Pages/Area/Residency and Passport/ResidencyPassport";
import WesternSahara from "./components/Pages/Jurisdictions/WesternSahara/WesternSahara";
import AssetProtection from "./components/Pages/Area/Asset Protection/AssetProtection";
import MwaliAutonomous from "./components/Pages/Jurisdictions/Mwali Autonomous/MwaliAutonomous";
import Anjouan from "./components/Pages/Jurisdictions/Anjouan/Anjouan";
import UAE from "./components/Pages/Jurisdictions/UAE/UAE";
import Cyprus from "./components/Pages/Jurisdictions/Cyprus/Cyprus";
import Italy from "./components/Pages/Jurisdictions/Italy/Italy";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Pages/Contact Us/Contact/Contact";
import BankingLicense from "./components/Pages/Services/Banking License/BankingLicense";
import LicenseMwali from "./components/Pages/Services/License Mwali/License Mwali";
import LicenseAnjouan from "./components/Pages/Services/License Anjouan/LicenseAnjouan";
import LicenseWesternSahara from "./components/Pages/Services/License Western Sahara/LicenseWesternSahara";
import BrokerageAndForexLicense from "./components/Pages/Services/Brokerage And Forex License/BrokerageAndForexLicense";
import BrokerageForexWesternSahara from "./components/Pages/Services/Brokerage Forex Western Sahara/BrokerageForexWesternSahara";
import BrokerageForexMwali from "./components/Pages/Services/Brokerage Forex Mwali/BrokerageForexMwali";
import BrokerageForexAnjouan from "./components/Pages/Services/Brokerage Forex Anjouan/BrokerageForexAnjouan";
import BrokerageForexCyprus from "./components/Pages/Services/Brokerage Forex Cyprus/BrokerageAndForexCyprus";
import BrokerageForexSeychelles from "./components/Pages/Services/Brokerage Forex Seychelles/BrokerageAndForexSeychelles";
import GamingGamblingLicense from "./components/Pages/Services/Gaming Gambling License/GamingGamblingLicense";
import GamingGamblingWesternSahara from "./components/Pages/Services/Gaming Gambling Western Sahara/GamingGamblingWesternSahara";
import GamingGamblingAnjouan from "./components/Pages/Services/Gaming Gambling Anjouan/GamingGamblingAnjouan";
import GamingGamblingCyprus from "./components/Pages/Services/Gaming Gambling Cyprus/GamingGamblingCyprus";
import ContactForm from "./components/Pages/Contact Us/Contact Form/Contact Form";
import OurServices from "./components/Pages/Our Services/OurServices";
import NonDomTaxRegime from "./components/Pages/Area/Non Dom Tax Regime/NonDomTaxRegime";
import HomeFooter from "./components/Home Footer/HomeFooter";
import SpecialOffer from "./components/Pages/Home/Special Offer/SpecialOffer";
import HomeContent from "./components/Pages/Home/HomeContent/HomeContent";
import BusinessList from "./components/Pages/Home/Business List/BusinessList";
import AnjouanFormation from "./components/Pages/Company Formation/Anjouan/Anjouan";
import WesternSaharaFormation from "./components/Pages/Company Formation/WesternSahara/WesternSahara";
import CyprusFormation from "./components/Pages/Company Formation/Cyprus/Cyprus";
import MwaliFormation from "./components/Pages/Company Formation/Mwali Autonomous/Mwali";

function App() {
    return (
        <BrowserRouter>
            <Main />
        </BrowserRouter>
    );
}

function Main() {
    const location = useLocation();
    const isHome = location.pathname === '/';

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={
                    <>
                        <Hero/>
                        <SpecialOffer/>
                        <HomeContent/>
                        <BusinessList/>
                        <OurServices />
                        <ContactForm />
                        <HomeFooter />
                    </>
                } />
                <Route path="/about" element={<About />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/offshore-company-registration" element={<OffshoreCompanyRegistration />} />
                <Route path="/license" element={<License />} />
                <Route path="/opening-bank-accounts" element={<OpeningBankAccounts />} />
                <Route path="/residency-passport" element={<ResidencyPassport />} />
                <Route path="/asset-protection" element={<AssetProtection />} />

                {/* LICENSES */}
                <Route path="/banking-license" element={<BankingLicense />} />
                <Route path="/license-western-sahara" element={<LicenseWesternSahara />} />
                <Route path="/license-mwali" element={<LicenseMwali />} />
                <Route path="/license-anjouan" element={<LicenseAnjouan />} />

                {/* BROKERAGE */}
                <Route path="/brokerage-forex-license" element={<BrokerageAndForexLicense />} />
                <Route path="/brokerage-forex-western-sahara" element={<BrokerageForexWesternSahara />} />
                <Route path="/brokerage-forex-mwali" element={<BrokerageForexMwali />} />
                <Route path="/brokerage-forex-anjouan" element={<BrokerageForexAnjouan />} />
                <Route path="/brokerage-forex-cyprus" element={<BrokerageForexCyprus />} />
                <Route path="/brokerage-forex-seychelles" element={<BrokerageForexSeychelles />} />

                {/* GAMING */}
                <Route path="/gaming-license" element={<GamingGamblingLicense />} />
                <Route path="/gaming-license-western-sahara" element={<GamingGamblingWesternSahara />} />
                <Route path="/gaming-license-anjouan" element={<GamingGamblingAnjouan />} />
                <Route path="/gaming-license-cyprus" element={<GamingGamblingCyprus />} />

                {/* OTHER PAGES */}
                <Route path="/western-sahara" element={<WesternSahara />} />
                <Route path="/italy" element={<Italy />} />
                <Route path="/cyprus" element={<Cyprus />} />
                <Route path="/uae" element={<UAE />} />
                <Route path="/anjouan" element={<Anjouan />} />
                <Route path="/mwali-autonomous" element={<MwaliAutonomous />} />
                <Route path="/non-dom-tax-regime" element={<NonDomTaxRegime />} />
                <Route path="/contact" element={<Contact />} />

                {/* COMPANY FORMATION */}
                <Route path="/anajouan-formation" element={<AnjouanFormation/>} />
                <Route path="/western-sahra-formation" element={<WesternSaharaFormation/>} />
                <Route path="/cyprus-formation" element={<CyprusFormation/>} />
                <Route path="/mwali-formation" element={<MwaliFormation/>} />
                


            </Routes>
            {!isHome && <OurServices />}
            {!isHome && <ContactForm />}
            {!isHome && <Footer />}
        </>
    );
}

export default App;