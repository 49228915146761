import React from 'react';
import './BusinessList.css'

function BusinessList() {
    return (
        <div className='wrapper'>
            <div className='business-container  container'>
                <h1 className='business-title section-subtitle'>
                    Businesses for sale
                </h1>
                <p className='business-description'>
                    We offer a variety of licenses for sale and are ready to accommodate your business structure
                    while making a great offer tailored to your wishes.
                </p>
                <div className='business-list-container'>
                    <ul className='business-list'>
                        <div>
                            <h3 className='business-list_title'>
                                Ready-made licenses for sale
                            </h3>
                            <li className='business-list-item'>EMI License</li>
                            <li className='business-list-item'>
                                Ready-made company – forex broker in
                                <br/>
                                Saint Vincent and the Grenadines
                            </li>
                            <li className='business-list-item'>Gambling license</li>
                            <li className='business-list-item'>Online bookmaker license in Cyprus</li>
                            <li className='business-list-item'>For sale ready MT5 license – forex broker</li>
                            <li className='business-list-item'>Financial license</li>
                            <li className='business-list-item'>Forex broker license</li>
                            <li className='business-list-item'>Fully licensed EMI UK company</li>
                            <li className='business-list-item'>Crypto Exchange & Wallet License For Sale</li>
                            <li className='business-list-item'>For sale beer retail license</li>
                            <li className='business-list-item'>Ready AEMI in UK for sale</li>
                            <li className='business-list-item'>License for a cryptocurrency company</li>
                        </div>

                        <div>
                            <h3 className='business-list_title'>
                                Ready-made companies for sale
                            </h3>
                            <li className='business-list-item'>UK AEMI holding in London for sale</li>
                            <li className='business-list-item'>Accounting</li>
                            <li className='business-list-item'>Management Company in Cyprus</li>
                            <li className='business-list-item'>Ready-made company in Cyprus with an account</li>
                            <li className='business-list-item'>Ready-made company in Comoros</li>
                            <li className='business-list-item'>Ready-made company with licenses for crypto exchange and
                                crypto wallet
                            </li>
                            <li className='business-list-item'>Ready-made company with an account</li>
                            <li className='business-list-item'>Custom-made company in Mwali with bank account for sale
                            </li>
                            <li className='business-list-item'>Ready-made company in Western Sahara</li>
                            <li className='business-list-item'>Bank Technology company for sale</li>
                            <li className='business-list-item'>Ready-made company in Anjouan</li>
                        </div>
                    </ul>
                </div>
            </div
            >
        </div>
    );
}

export default BusinessList;
