import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/jurisdictions/italy/hero-image.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Italy',
    link: '/italy',
  },
]

function Italy() {
  return (
      <main className='main'>
        <section className='section'>
          <BreadCrumbs data={BreadCrumbsData}/>

          <div className='container'>
              <h1 className='section-title'>Italy</h1>

              <div className='grid-container'>
              <div className='flex-start'>
                  <h2 className='page-subtitle'>Establishing Excellence in Living and Investing in Italy</h2>
                  <p className='page-paragraph'>
                      Italy, renowned for its rich cultural heritage, strategic location
                    in Europe, and robust economy, offers attractive programs for
                    residency, citizenship, and investment. Governed by progressive
                    regulatory authorities, Italy provides a business-friendly
                    environment with a stable legal framework, making it an ideal
                    destination for those seeking to establish a presence in Europe.
                  </p>
                  <p className='page-paragraph'>
                      At Offshore Advisory, we specialize in navigating the intricacies of
                    Italy’s regulatory landscape. Our comprehensive suite of services is
                    designed to facilitate seamless residency applications, citizenship
                    acquisitions, and investment strategies.
                  </p>
                </div>
                <HeroRightImage src={HeroImage} />
              </div>
              
             <div className='flex-start'>
                <p className='page-subtitle'>
                  We are dedicated to providing expert guidance and support for the
                  following services:
                </p>
                <h2 className='page-subtitle'>Residency and Citizenship</h2>
                <h3 className='page-title'>Residency in Italy</h3>
                 <p className='page-paragraph'>
                     Secure your residency in Italy through various investment and business
                  opportunities. Italy offers a range of residency options, including:
                </p>
                 <p className='page-paragraph'>
                     Investor Visa: By investing in Italian companies, purchasing
                  government bonds, or making philanthropic contributions, you can
                  obtain an investor visa that grants residency for you and your family.
                </p>
                <p className='body-pargraph'>
                  Elective Residency Visa: Ideal for retirees and individuals with high
                  self-sustaining income, this visa allows you to enjoy living in Italy
                  without engaging in work.
                </p>
                 <p className='page-paragraph'>
                     Start-Up Visa: For entrepreneurs looking to establish innovative
                  businesses, the start-up visa offers residency through investment in
                  the Italian economy.
                </p>
                 <p className='page-paragraph'>
                     Our team guides you through the application process, ensuring you meet
                  all the requirements and benefit from the residency privileges.
                </p>
                <h3 className='page-title'>Citizenship and Passport</h3>
                 <p className='page-paragraph'>
                     While obtaining Italian citizenship typically involves a longer
                  process, including residency requirements, our experts provide
                  advisory services on how to expedite this through investment and other
                  eligible pathways. We offer strategic advice to help you achieve your
                  long-term residency and citizenship goals in Italy.
                </p>
             </div>
            <div className='flex-start'>
                <h2 className='page-subtitle'>Investment Opportunities</h2>
                <h3 className='page-title'>Real Estate Investment</h3>
                <p className='page-paragraph'>
                    Italy’s real estate market is one of the most attractive in Europe,
                  offering a wide range of investment opportunities. From historic
                  villas and countryside estates to modern apartments in bustling
                  cities, you can benefit from high returns and capital appreciation.
                  Our team provides comprehensive support, from property selection to
                  transaction completion, ensuring you make the best investment
                  decisions.
                </p>
                <h3 className='page-title'>Business Investment</h3>
                <p className='page-paragraph'>
                    Italy provides numerous opportunities for business investments,
                  including establishing companies in various sectors such as
                  technology, manufacturing, tourism, and fashion. Benefit from Italy’s
                  strategic location, highly skilled workforce, and business-friendly
                  policies. We assist in identifying the best investment opportunities
                  and navigating the regulatory requirements to ensure your business
                  thrives.
                </p>
                <h3 className='page-title'>Financial Investment</h3>
                <p className='page-paragraph'>
                    Explore diverse financial investment options in Italy, including
                  stocks, bonds, mutual funds, and other financial instruments. Our
                  experts provide tailored investment strategies to help you maximize
                  returns and achieve your financial goals. Italy’s well-regulated
                  financial market ensures a secure environment for your investments.
                </p>
            </div>

            <div className='flex-start'>
                <h2 className='page-subtitle'>Benefits of Choosing Italy</h2>
                <h3 className='page-title'>Regulatory Advantages</h3>
                <p className='page-paragraph'>
                    The regulatory landscape of Italy is designed to accommodate
                    international investors, providing a secure and compliant platform for
                    financial and business operations. The country’s regulatory
                    authorities ensure that regulations are clear, consistent, and
                    conducive to growth, allowing you to operate with confidence.
                </p>
                <h3 className='page-title'>Tax Efficiency</h3>
                <p className='page-paragraph'>
                    Italy offers favorable tax structures for residents and investors,
                    including various incentives and benefits for foreign nationals. The
                    country’s tax policies enable you to maximize your financial
                    performance and reinvest savings into your ventures.
                </p>
                <h3 className='page-title'>Global Accessibility</h3>
                <p className='page-paragraph'>
                    Strategically located, Italy serves as a gateway to Europe and beyond,
                    facilitating international business and travel. The country’s
                    strategic location provides unparalleled access to key markets,
                    enhancing your business’s reach and growth potential.
                </p>
                <h3 className='page-title'>Cultural and Lifestyle Benefits</h3>
                <p className='body-pargraph'>
                  Italy is renowned for its high quality of life, rich cultural
                  heritage, and stunning landscapes. From world-class cuisine and
                  fashion to historic landmarks and vibrant cities, living in Italy
                  offers an unparalleled lifestyle experience.
                </p>
              </div>

          </div>
        </section>
      </main>


  )
}

export default Italy
