import React from "react";
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import HeroImage from "../../../../Assets/images/company-formation/cyprus-formation/hero-image.png";
import BannerImage from "../../../../Assets/images/company-formation/cyprus-formation/banner-image.png";
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaDiamond} from "react-icons/fa6";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";

const BreadCrumbsData = [
    {name: "Offshore Advisory", link: "/"},
    {
        name: "Cyprus",
        link: "/cyprus-formation",
    },
];

function CyprusFormation() {
    return (
        <main className="main">
            <section className="section">
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className="container">
                    <h1 className="section-title">Cyprus Offshore Company Formation</h1>

                    <div className="grid-container">
                        <div className="flex-start">
                            <h2 className="page-subtitle">Offshore Company Registration</h2>
                            <p className="page-paragraph">
                                Cyprus is a premier destination for offshore company formation,
                                renowned for its favorable tax regime, strategic location, and
                                business-friendly environment. Whether you're a multinational
                                enterprise or a budding entrepreneur, incorporating an offshore
                                company in Cyprus can provide significant benefits.
                                Understanding the legal framework and registration process is
                                crucial to maximize the potential of your offshore business.
                            </p>

                            <h3 className="page-title">
                                What is an Offshore Company in Cyprus?
                            </h3>
                            <p className="page-paragraph">
                                An offshore company in Cyprus refers to a legal entity
                                established to operate internationally, benefiting from the
                                island’s tax-efficient structure while having minimal physical
                                presence in the jurisdiction. Cyprus is not a "classic" offshore
                                destination with zero taxes but offers a highly competitive
                                corporate tax rate and access to numerous double taxation
                                treaties, making it an attractive option for global businesses.
                            </p>
                        </div>
                        <HeroRightImage src={HeroImage}/>
                    </div>

                    <BannerPageImg src={BannerImage}/>

                    <div className="flex-start">
                        <h3 className="page-subtitle">
                            Advantages of Cyprus Offshore Companies
                        </h3>
                        <p className="page-subtitle">
                            Cyprus offers a range of benefits for offshore businesses,
                            including:
                        </p>
                        <ul className="page-list margin-bottom">
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Low Corporate Tax </span>
                                    {/*  */} A corporate tax rate of 12.5%, one of the lowest in the
                                    European Union.
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">EU Membership</span>
                                    {/*  */} Access to the EU single market and adherence to international standards.
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Extensive Tax Treaties</span>
                                    {/*  */} Over 60 double taxation agreements, minimizing tax liabilities.
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Dividend Exemption </span>
                                    {/*  */} No withholding tax on dividends paid to non-residents.
                                </p>
                            </li>

                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Reputable Jurisdiction</span>
                                    {/*  */} Unlike traditional offshore zones, Cyprus is a respected international
                                    business hub.
                                </p>
                            </li>

                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Efficient Banking System</span>
                                    {/*  */} Access to robust banking services and international transactions.
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    <span className="page-list-title">Confidentiality</span>
                                    {/*  */} Limited disclosure of beneficial owners under strict legal frameworks.
                                </p>
                            </li>
                        </ul>

                        <h3 className="page-title">Common Uses for Cyprus Offshore Companies</h3>
                        <p className='page-subtitle'>
                            Businesses in Cyprus are typically used for:
                        </p>
                        <ul className="page-list margin-bottom">
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    Holding and managing investments
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    International trade and logistics
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    Intellectual property management
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    E-commerce and online businesses
                                </p>
                            </li>

                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    Financial services and forex trading
                                </p>
                            </li>

                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    Asset protection and estate planning
                                </p>
                            </li>
                            <li className="page-list-item">
                                <div className="page-list-icon-box">
                                    <FaDiamond className="list-diamon-icon"/>
                                </div>
                                <p className="page-list-paragraph">
                                    Real estate development and ownership
                                </p>
                            </li>
                        </ul>

                        <h3 className='page-title'>Steps to Register an Offshore Company in Cyprus</h3>
                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Consultation
                                        </span>{/*  */} Work with experienced advisors to determine the best structure
                                    for your business.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Choose a Name
                                        </span>{/*  */} Submit your preferred company name for approval by the Registrar
                                    of Companies.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Prepare Documentation
                                        </span>{/*  */} Provide notarized identification and proof of address for
                                    directors, shareholders, and beneficial owners.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Draft Incorporation Documents
                                        </span>{/*  */} Prepare the memorandum and articles of association.
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Incorporation
                                        </span>{/*  */} Register your company with the Cyprus Registrar of Companies.
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Bank Account Setup
                                        </span>{/*  */} Open a corporate bank account to facilitate international
                                    transactions.
                                </p>
                            </li>

                        </ul>
                        <p className='page-paragraph'>
                            The registration process is straightforward but requires compliance with local regulations
                            and
                            international standards.
                        </p>

                        <h3 className='page-title'>Cost of Offshore Company Formation in Cyprus</h3>
                        <p className='page-paragraph'>
                            The cost of forming an offshore company in Cyprus varies depending on the services required,
                            such
                            as legal consultation, bank account setup, and additional licensing. Our team provides
                            customized
                            pricing based on your business needs.
                        </p>

                        <h3 className='page-title'>
                            Our Services for Cyprus Offshore Companies
                        </h3>
                        <p className='page-subtitle'>
                            We offer end-to-end support for offshore company formation, including:
                        </p>
                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Company incorporation in Cyprus
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Tax planning and compliance services
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Bank account opening assistance
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Accounting, auditing, and annual report preparation
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Corporate secretarial services
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Legal and regulatory support
                                </p>
                            </li>
                        </ul>
                        <p className='page-paragraph'>
                            Our experts handle every detail, ensuring your company complies with Cyprus laws while
                            maximizing its benefits.
                        </p>

                        <h3 className='page-title'>
                            Why Choose an Offshore Company in Cyprus?
                        </h3>
                        <p className='page-subtitle'>
                            Cyprus is an ideal jurisdiction for offshore businesses due to its:
                        </p>
                        <ul className='page-list margin-bottom'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Favorable Tax System
                                        </span>{/*  */} One of the most competitive corporate tax rates in the EU.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Strategic Location
                                        </span>{/*  */} Serving as a gateway between Europe, the Middle East, and Asia.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Reputation
                                        </span>{/*  */} A legitimate and respected business hub, avoiding the stigma of
                                    "tax havens."
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                        <span className='page-list-title'>
                                          Ease of Doing Business
                                        </span>{/*  */} Streamlined processes for incorporation and banking.
                                </p>
                            </li>
                        </ul>

                        <h3 className='page-title'>
                            Get Started with Cyprus Offshore Formation
                        </h3>
                        <p className='page-paragraph'>
                            Cyprus offers an exceptional platform for businesses seeking international growth, tax
                            efficiency, and operational flexibility. Whether you're looking to expand your global reach
                            or protect your assets, our team of professionals is here to guide you every step of the
                            way.
                        </p>

                        <strong className='page-subtitle'>
                            Contact us today for expert advice and seamless offshore company registration in Cyprus!
                        </strong>

                    </div>
                </div>
            </section>
        </main>
    );
}

export default CyprusFormation;
