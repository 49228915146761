import React from 'react';
import HeroImage from '../../../../Assets/images/area/opening-bank-account/hero-img.png'
import PageBannerImage from '../../../../Assets/images/area/opening-bank-account/banner-img.png'
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Opening bank accounts',
        link: '/opening-bank-accounts',
    },
]

function OpeningBankAccounts () {
    return (
        <main className='main'>
            <section className='banking-license-section section'>
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className='banking-license-container container '>
                    <h1 className='section-title'>Opening bank accounts</h1>
                    <div className='licensing-container grid-container'>
                        <div className='flex-start'>

                            <p className='body-paragraph'>Requirements for banking systems are increasingly stringent,
                                prompting several countries
                                to implement automatic financial and tax information exchanges. This regulatory
                                tightening aims to monitor citizen cash flows more closely, thus necessitating stricter
                                standards for company documentation.
                            </p>


                            <p className='body-paragraph'>
                                Our company excels in providing efficient and cost-effective services for opening
                                offshore bank accounts. With extensive experience and a network of global banking
                                partners, we streamline the entire process for your business.
                            </p>
                            <br/>

                            <div className='flex-start'>
                                <h3 className='page-title'>
                                    Benefits of an offshore Account include:
                                </h3>

                                <ul className='page-list'>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Enhanced privacy.
                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Attractive investment opportunities.
                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            High reliability.
                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Legal guarantees for fund retrieval in case of bank insolvency.

                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Reduced political risks.
                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Freedom in fund management.
                                        </p>
                                    </li>
                                    <li className='page-list-item'>
                                        <div className='page-list-icon-box'>
                                            <FaDiamond className='list-diamon-icon'/>
                                        </div>
                                        <p className='page-list-paragraph'>
                                            Low or absent tax rates.
                                        </p>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <HeroRightImage src={HeroImage}/>
                    </div>

                    <BannerPageImg src={PageBannerImage} />

                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Types of bank accounts:
                        </h3>

                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Commercial accounts: Ideal for active businesses like import/export or online
                                    consulting, though they require more rigorous maintenance and compliance with laws,
                                    attracting minimal transaction fees which might not be preferable for banks.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Private banking with personal capital: Suitable for non-commercial transactions,
                                    with
                                    funds deposited all at once. This includes minimal operations, possibly needing a
                                    credit
                                    card for personal expenses, aiming for a personalized banking relationship.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Offshore retail accounts: Perfect for entrepreneurs testing offshore waters without
                                    the
                                    intention of extensive transactions or large deposits. These can be opened by
                                    individuals or legal entities.
                                </p>
                            </li>
                        </ul>
                        <p className='page-paragraph'>
                            Our experts provide comprehensive financial assistance and offshore account opening
                            services. We also specialize in company formation and licensing, including sectors like
                            cryptocurrency and gaming.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Steps to open an offshore bank account:
                        </h3>

                        <ol className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Prepare essential documentation including company constitution and owner identities.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Consult with our managers to choose the optimal bank.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Submit a preliminary request to the bank, awaiting feedback.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Complete and submit required forms post-preliminary approval.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Account setup and management initiation.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Some banks might require the presence of the director during the process.
                                </p>
                            </li>
                        </ol>
                        <p className='page-paragraph'>
                            Our service fees are for assisting in the account opening, but account approval ultimately
                            depends on the bank's/EMI's review of submitted documents and responses.
                        </p>
                    </div>
                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Documents required typically include:
                        </h3>

                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Apostilled company documents and proof of officials’ authority.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Company reputation proof, if available.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Licenses and proof of a physical company office.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Director’s notarized passport and recent utility bills.
                                </p>
                            </li>
                        </ul>
                        <p className='page-paragraph'>
                            Our service fees are for assisting in the account opening, but account approval ultimately
                            depends on the bank's/EMI's review of submitted documents and responses.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Opening an account in an EMI-system offers:
                        </h3>

                        <ul className='page-list page-paragraph'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Faster transactions.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Enhanced security and data protection.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Remote operation convenience.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Lower maintenance and transaction fees.
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Anytime, anywhere account access.
                                </p>
                            </li>
                        </ul>
                        
                        <p className='page-paragraph'>
                            <h3 className='page-title'>
                                How to open an offshore bank account online:
                            </h3>
                            Contact a bank representative, prepare, and submit necessary documents in digital format.
                            Our experts provide remote, professional support to ensure the highest quality service.

                        </p>
                        <p className='body-paragraph'>
                            <h3 className='page-title'>
                                What are offshore banking services?
                            </h3>
                            Banking in offshore institutions is known for low taxes, high reliability, and enhanced
                            confidentiality. We are committed to offering the best services for opening offshore
                            accounts.
                        </p>
                    </div>

                </div>
            </section>

        </main>
    );
}

export default OpeningBankAccounts;
