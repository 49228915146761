import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import './Contact Form.css';
import {FaTelegramPlane} from "react-icons/fa";
import {IoLogoWhatsapp} from "react-icons/io";

const ContactForm = () => {
    const albaniaTelegram = '+355693377716';
    const albaniaTelegramUrl = `https://t.me/${albaniaTelegram}`;

    const cyprusTelegram = '+35796782975';
    const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

    const albaniaWhatsappUrl = `https://wa.me/${albaniaTelegram.replace('+', '')}`;
    const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');

    const clearFeedbackMessage = () => {
        setTimeout(() => {
            setFeedbackMessage('');
            setMessageClass('');
        }, 3000);  // 3000 milliseconds = 3 seconds
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your actual values from EmailJS
        emailjs.sendForm('service_avb52ot', 'template_3wurise', e.target, 'ZKWr_IwSWOk9MO-mM')
            .then((result) => {
                console.log(result.text);
                setFeedbackMessage('Message sent successfully!');
                setMessageClass('success');
                clearFeedbackMessage();  // Call to clear the message after a few seconds
            }, (error) => {
                console.log(error.text);
                setFeedbackMessage('Failed to send the message, please try again later.');
                setMessageClass('error');
                clearFeedbackMessage();
            });

        e.target.reset();
    };

    return (
        <div className='wrapper'>
            <div className='contact-container container'>
                <div className="contact-form-container">
                    <h2 className='contact-form-title'>Fill the Blank:</h2>
                    <form className='contact-form' onSubmit={sendEmail}>
                        <input
                            className='contact-form-input'
                            type="text"
                            id="name"
                            name="name"
                            placeholder='Full name'
                            required
                        />
                        <input
                            className='contact-form-input'
                            type="email"
                            id="email"
                            name="email"
                            placeholder='example@company.com'
                            required
                        />
                        <textarea
                            className='contact-form-input'
                            id="message"
                            name="message"
                            rows={5}
                            placeholder='Short brief*'
                            required
                        />
                        <button className='contact-button' type="submit">
                            Submit
                        </button>
                    </form>
                    {feedbackMessage && <p className={`feedback-message ${messageClass}`}>{feedbackMessage}</p>}
                </div>

                <div className="contact-info">
                    <div className='contact-info-item'>
                        <h2 className='contact-info-title'>Albania</h2>
                        <div className='contact-info-content'>
                            {albaniaTelegram}
                            <a href={albaniaTelegramUrl} className='contact-info-link'>
                                <FaTelegramPlane/>
                            </a>
                            <a href={albaniaWhatsappUrl} className='contact-info-link'>
                                <IoLogoWhatsapp/>
                            </a>
                        </div>
                    </div>

                    <div className='contact-info-item'>
                        <h2 className='contact-info-title'>Cyprus</h2>
                        <div className='contact-info-content'>
                            {cyprusTelegram}
                            <a href={cyprusTelegramUrl} className='contact-info-link'>
                                <FaTelegramPlane/>
                            </a>
                            <a href={cyprusWhatsappUrl} className='contact-info-link'>
                                <IoLogoWhatsapp/>
                            </a>
                        </div>
                    </div>

                    <div className='contact-info-item'>
                        <h2 className='contact-info-title'>United Kingdom</h2>
                        <div className='contact-info-content'>
                            <p>+44 204 577 43 28</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
