import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/services/gaming-gambling-anjouan/hero-image.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Gaming and Gambling In Anjouan',
    link: '/gaming-and-gambling-anjouan',
  },
]

function GamingGamblingAnjouan() {
  return (
    <main className='main'>
      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />

        <div className='container'>

          <h1 className='section-title'>Gaming and Gambling license in Anjouan</h1>

          <div className='grid-container'>
            <div>
              <p className='page-paragraph'>
                Online Betting and gaming licenses are issued only to Anjouan
                companies, but allow a company to engage in as many different
                kinds of on line gambling promotions and under as many different
                brand names or web addresses as desired. Local nominee directors
                are acceptable if required. A fixed fee per year is charged. The
                Server may be located anywhere in the world, and profits may be
                cleared through any bank.
              </p>
              <p className='page-paragraph'>
                Betting and Gaming Law The Betting and Gaming Act 2005 was
                passed to legalise and regulate computer internet gaming, horse
                or greyhound betting and sports book betting, Casino, lottery
                and bingo on line gaming, to establish a Gaming Board, and to
                provide for related or incidental matters.
              </p>
            </div>
            
            <HeroRightImage src={HeroImage}/>
          </div>
          
          <div className='body-content'>
            <h3 className='page-title'>Formation</h3>
            <p className='page-paragraph'>
              The formation of an Online betting or gaming company has to be
              made on the application form as stipulated in the act and the
              necessary KYC and due diligence documents as below to the Anjouan
              Corporate Services Ltd and Gaming Board of Anjouan. The Gaming
              Board of Anjouan will then assess the application the Directors
              and shareholders before grating the permission for the license.
            </p>
            <h3 className='page-title'>KYC AND DUE DILIGENCE</h3>
            <p className='page-paragraph'>
              Please submit the following documents for each of the beneficial
              owner, director, manager, authorised signatory, shareholder/member
              of the Entity or agent under power of attorney.
            </p>
            <h3 className='page-subtitle'>(a) For Individual</h3>
            <p className='page-paragraph'>
              A certified copy of passport or current driving licence or
              national identity card showing the name, date and place of birth,
              nationality, signature of holder and data of issue/expiry. The
              document must be certified by a banker, lawyer or actuary or
              notary or accountant holding a recognized professional
              qualification. Proof of residential address. Acceptable evidence
              includes an original utility bill, bank or credit card statement.
              Original Bank Reference Certificate of Morality (Affidavit
              confirming that the individuals have no criminal track record).
            </p>
            <h3 className='page-subtitle'>(b) For Corporate/Partnership</h3>
            <p className='page-paragraph'>
              Certificate of incorporation or registration and the memorandum
              and articles of association or constitution or deed of partnership
              or Act de Soci?t? as the case may be. Document has to be certified
              by banker, lawyer or actuary or notary or accountant holding a
              recognized professional qualification. Original Bank Reference.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default GamingGamblingAnjouan
