import React from 'react';
import './About.css'
import { FaDiamond } from "react-icons/fa6";
import AboutVector_12 from '../../../../Assets/images/about/vector-12.png';
import AboutVector_13 from '../../../../Assets/images/about/vector-13.png';
import AboutVector_14 from '../../../../Assets/images/about/vector-14.png';
import AboutVector_15 from '../../../../Assets/images/about/vector-15.png';
import AboutVector_16 from '../../../../Assets/images/about/vector-16.png';
import AboutVectorOne from '../../../../Assets/images/about/hero-image.png';
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import VectorImageBox from "../../../Constant/VectorImage/VectorImageBox";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'About the Company',
        link: '/about',
    }
]

function About() {
    return (
        <main className='main'>
            <section
                className='about-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className="about-container container">

                    <div className='about-header'>
                        <h1 className="section-title">
                            About the Company
                        </h1>
                        <p className='pargraph'>
                            Offshore Advisory provides professional consulting, legal, tax and auditing services.
                        </p>
                        <p className='pargraph'>
                            Our specialists provide the following services globally.
                        </p>
                    </div>

                    <div className='about-columns'>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p>
                                Registration and Planning for International Business
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                International Tax Advisory
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                International Consulting services
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Accounting and Financial Reporting Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Evaluation and Business Modelling
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Tax Policy and Tax Dispute Resolution Advice
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Transfer Pricing Services
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Strategic Advice for Growth Markets
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Transaction Advisory Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Advice on Restructuring
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Asset Sale Advisory Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Real
                                Estate Consulting Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Support for Mergers and Acquisitions
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Services for the operational integration of transactions;
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>
                                Increase business efficiency;
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>Management and Information Technology</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p
                                className='about-column-descrioption'>Consulting Services for the Financial Sector</p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p
                                className='about-column-descrioption'>Business Risk Management Advice</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>                            
                            <p className='about-column-descrioption'>Services for Clean Technology and Sustainable
                                Development</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>                            <p className='about-column-descrioption'>Financial Reporting Advisory Services (FAAS)</p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>                            <p
                                className='about-column-descrioption'>Investigation of Fraud and Conflict Resolution
                                Advice</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>Audit
                                Services</p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>                            <p className='about-column-descrioption'>Asset
                                Protection Service</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>                            <p className='about-column-descrioption'>Accompanying the opening of bank accounts and
                                acquiring;</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>Guidance and Support to Obtain Licenses for
                                Gambling
                                Activities
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>
                                Guidance and Support to Obtain Licenses for Forex
                                Activities
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p
                                className='about-column-descrioption'>Facilitate Ownership of Financial Licenses</p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>Support
                                in Obtaining Licenses for Cryptocurrencies and ICO</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>Support
                                of mergers and acquisitions (MNA)</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>Registration of Aircraft and Marine Craft</p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>
                            Provision of Virtual Office Services
                            </p></div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon'/>
                            </div>
                            <p className='about-column-descrioption'>Obtain
                                and Establish Trademarks, Logo and Marks 
                            </p>
                        </div>
                        <div className='about-column'>
                            <div>
                                <FaDiamond className='list-diamon-icon' />
                            </div>
                            <p className='about-column-descrioption'>Design
                                and Protection of Intellectual Property
                            </p>
                        </div>
                    </div>
                </div>

                <div className='container wrapper-block'>
                    <h3 className='page-title'>We work adhering to the principles:</h3>
                    <br/>
                    <div className='about-figure flex-wrap'>
                        <VectorImageBox
                            src={AboutVector_12}
                            vectorImageTitle="Confidentiality"
                        />

                        <VectorImageBox
                            src={AboutVector_13}
                            vectorImageTitle="Data protection"
                        />

                        <VectorImageBox
                            src={AboutVector_14}
                            vectorImageTitle="Legality"
                        />

                        <VectorImageBox
                            src={AboutVector_15}
                            vectorImageTitle="Individual approach to the client"
                        />

                        <VectorImageBox
                            src={AboutVector_16}
                            vectorImageTitle="Completion of tasks
                                within the agreed time frame"
                        />
                     
                    </div>
                </div>

                <div className='about-tax-grid container padding-block'>
                    <div className='about-tax-content'>
                        <div className='about-main-tasks about-grid-item'>
                            <h3 className='page-title'>OUR MAIN TASKS:</h3>
                            <p className='page-paragraph'>
                                Help clients reduce tax liabilities through legal methods.<br/>
                                Safeguard client assets from potential risks.<br/>
                                Offer informed advice derived from an in-depth review of the state’s legal system to
                                improve
                                client financial outcomes.<br/>
                                Provide assistance with pre-trial or court settlements of corporate disputes across
                                various
                                jurisdictions.<br/>
                                Maintain support for client businesses throughout all phases of their operations.<br/>
                            </p>
                        </div>

                        <div className='about-specialists '>
                            <h3 className='page-title'>OUR SPECIALISTS:</h3>
                            <p className='page-paragraph'>
                                Provide customized solutions based on the specific situation;<br/>
                                Offer customers only legally sound and optimal solutions for a specific situation;<br/>
                                Warn the client in advance about the possible consequences of the decisions made;<br/>
                                Keep customer data confidential;<br/>
                                We act strictly in accordance with the rules of attorney ethics;<br/>
                                We are constantly improving the knowledge and skills of applying Ukrainian legislation
                                and a
                                number of laws of other countries.<br/>
                            </p>
                        </div>
                        
                        <div className='about-advantages about-grid-item'>
                            <h3 className='page-title'>WHAT ARE OUR ADVANTAGES:</h3>
                            <p className='page-paragraph'>
                                The presence of lawyers in our company provides additional guarantees of our
                                professionalism, as well as<br/>
                                guarantees of confidentiality, because lawyer secrecy applies to any information that a
                                client conveys about himself
                                or his activities, information in any form is not subject to disclosure to third parties
                                without the client’s consent;<br/>
                                Our company has a unique experience and knowledge of the specifics of work in most
                                countries
                                around the world <br/>
                                We develop solutions based on the analysis of legislation and look to the future,
                                studying
                                possible changes in the laws of specific states.<br/>
                                Customer safety is most important to us;<br/>
                                We value every customer <br/>
                            </p>
                        </div>
                    </div>

                    <img src={AboutVectorOne} alt='about-vector-one' className='about-vector-one'/>
                </div>


            </section>


        </main>
    );
}

export default About;
