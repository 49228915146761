import React from 'react'
import './HomeFooter.css'

import {
  FaSkype,
  FaFacebook,
  FaLinkedin, FaTelegramPlane,
} from 'react-icons/fa'
import {IoLogoWhatsapp} from "react-icons/io";

function HomeFooter() {

  const cyprusTelegram = '+35796782975';
  const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

  const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;


  return (
      <main className='main-footer'>
        <div className='footer container'>
          
          <div></div>
          
          <div className='social-btns'>
            <a href='https://join.skype.com/invite/AdDbThJOeejF' target='_blank'>
              <FaSkype/>
            </a>
            <a href='https://www.facebook.com/share/vC5S5JbubCZ47WRx/?mibextid=LQQJ4d' target='_blank'>
              <FaFacebook/>
            </a>
            <a href={cyprusTelegramUrl} className='contact-info-link' target='_blank'>
              <FaTelegramPlane/>
            </a>
            <a href='/'>
              <FaLinkedin/>
            </a>
            <a href={cyprusWhatsappUrl} className='contact-info-link' target='_blank'>
              <IoLogoWhatsapp/>
            </a>
          </div>
        </div>

        <div className='home-footer-content'>

          <div className="container home-footer-columns">

            <div>
              <p className='body-pargraph'>
                Since its founding, Offshore Advisory has supported thousands of clients worldwide. We collaborate with
                a
                variety of sectors including banking and payment institutions, international trade organizations,
                cryptocurrency exchanges, digital technology firms, outsourcing and logistics companies, gambling
                enterprises, IT infrastructures, and many more. Our experts are well-versed in the legal intricacies and
                regulatory frameworks of the countries we operate in, enabling us to deliver highly effective outcomes
                for
                our clients.
              </p>

              <div className='home-footer-column'>
                <h3 className='container-title'>Our services</h3>
                <p className='body-pargraph'>Offshore Advisory offers a vast range of corporate services, as well as expert consulting for
                  organizations and entrepreneurs.</p>
              </div>

              <div className='home-footer-column'>
                <h3 className='container-title'>
                  Offshore Licensing
                </h3>
                <p className='body-pargraph'>
                  We offer expert services in offshore licensing and business setup, guiding clients through the
                  complexities of establishing and managing corporate entities in various jurisdictions around the
                  world.
                </p>
              </div>

              <div className='home-footer-column'>
                <h3 className='container-title'>Bank Account</h3>
                <p className='body-pargraph'>We assist clients in managing banking accounts across hundreds of states worldwide and support the
                  acquisition of active commercial structures through ready businesses. Our specialists are dedicated to
                  helping interested customers navigate these processes.</p>
                <p>Registration of firms, assisting in opening bank accounts and payment systems evolving.</p>
              </div>
            </div>

            <div>
              <div className="home-footer-column">
                <h3 className='container-title'>Regulatory Compliance</h3>
                <p className='body-pargraph' >We provide comprehensive support in regulatory compliance, ensuring that our clients' operations
                  adhere to all applicable laws and regulations in their respective jurisdictions.</p>
              </div>

              <div className="home-footer-column">
                <h3 className='container-title'>Spheres of activity</h3>
                <p className='body-pargraph' >We provide global corporate and legal support to businesses. Our experts specialize in helping
                  companies obtain licenses and establish commercial entities, offering guidance on tax matters,
                  assisting with opening bank accounts, launching businesses, and consulting on the legal intricacies of
                  various countries. Additionally, we offer a comprehensive selection of ready-made companies and
                  licenses across the globe.</p>
              </div>

              <div className='home-footer-column'>
                <h3 className='container-title'>
                  Our achievements
                </h3>
                <p className='body-pargraph' >
                  Offshore Advisory supports thousands of clients on an international scale. We collaborate with leading
                  global organizations to deliver top-tier assistance to various firms.
                </p>
              </div>
            </div>

          </div>
        </div>
      </main>
  )
}

export default HomeFooter
