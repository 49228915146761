import React from 'react';
import HeroImage from '../../../../Assets/images/services/banking-license-in-anjouan/hero-image.png'
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Banking License in Anjouan',
        link: '/license',
    },
]

function LicenseAnjouan () {
    return (
        <main className='main'>
            <section
                className='banking-license-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>
                <div className='banking-license-container container' >

                    <h1 className='section-title'>Banking License in Anjouan</h1>

                    <div className='licensing-container grid-container '>
                    <div className='flex-start'>
                            <p className='offshore-text'>
                                An International banking License ( Class B ) is the only Banking License offered in Anjouan and is an offshore banking license granted, by the Anjouan Offshore Finance Authority. This is a full-unrestricted Banking license, for all Banking services outside of Anjouan, Union of Comoros. It encompasses banking business and investment banking business conducted in all major foreign currencies. Services such as fund administration, custodial services, trusteeship, international portfolio management, money market and other treasury operations may be undertaken by an offshore bank.  Forming a bank is a relatively quick process due to the lack of bureaucracy. Approval can be granted swiftly if the necessary requirements are met. There are no requirements for local offices or staff, although we would suggest that all companies have this facility which can be arranged at a minimal cost.
                            </p>
                        </div>

                        <HeroRightImage src={HeroImage} />
                    </div>
                    
                    <div className='flex-start'>
                        <p className='body-pargraph'>
                            Application for permission for a banking license should be made to the Anjouan Offshore Finance Authority after an IBC is obtained after completing an IFXBG application forms from the Registrar of International Businesses. On top of the KYC and due diligence requirement, the following documents are required:
                        </p>
                        <ol className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    A detailed business plan of the company: Activities of the bank; Countries in which
                                    the
                                    bank is looking to invest; The percentage of ownership by the shareholders; The
                                    details
                                    of the ultimate beneficial owners (chart may help if many); Any other useful
                                    information
                                    to support the application.
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    CVs of all the directors
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Bank reference for all the beneficial owners
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Clear certified passport copies of all beneficial owners
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Proof of addresses (utility bills)
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Business or legal reference for the beneficial owners
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Legal doc for the shareholding companies (articles, certificate of incorporation etc)
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Latest audited financial statements for all shareholding companies (if any)
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    A certificate to prove that the beneficial owners do not have a criminal record
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Furthermore, all companies with banking licenses are required to submit their annual
                                    accounts to the Authority                                </p>
                            </li>
                        </ol>
                    </div>

                    <div className='flex-start'>
                        <h3 className='page-title'>
                            KYC AND DUE DILIGENCE
                        </h3>
                        <p className='body-pargraph'>
                            A charge is made for each director / shareholder for due diligence (cost is dependent of
                            country of residence), in the event that a client is not accepted for a license this payment
                            is not refundable.
                        </p>
                        <p className='body-pargraph'>
                            The following documents for each of the beneficial owner, director, manager, authorized
                            signatory, shareholder/member of the Entity or agent under power of attorney must be
                            submitted.
                        </p>
                        
                        <h3 className='page-subtitle'>
                            (a) For Individual
                        </h3>
                        <p className='body-pargraph'>
                            A certified copy of passport or current driving license or national identity card showing
                            the name, date and place of birth, nationality, signature of holder and data of
                            issue/expiry. The document must be certified by a banker, lawyer or actuary or notary or
                            accountant holding a recognized professional qualification. Proof of residential address.
                            Acceptable evidence includes an original utility bill, bank or credit card statement.
                            Original Bank Reference.
                        </p>
                        <h3 className='page-subtitle'>
                            (b) For Corporate/Partnership
                        </h3>
                        <p className='body-pargraph'>
                            Certificate of incorporation or registration and the memorandum and articles of association
                            or constitution or deed of partnership or Act de Société as the case may be. Document has to
                            be certified by banker, lawyer or actuary or notary or accountant holding a recognized
                            professional qualification. Original Bank Reference.
                        </p>
                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    A certified true copy of latest audited account or annual report.
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    A Certificate of good standing (for Société).
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    List of substantial shareholders (who holds not less than 5% of the voting power
                                    exercisable at the meeting of shareholder/member) and a list of directors, and
                                    corporate
                                    structure.
                                </p>
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    A certified copy of Board resolution granting authority to its managers, officers or
                                    employees to transact business on its behalf.
                                </p>
                            </li>
                        </ul>

                    </div>
                </div>
            </section>
        </main>
    )
        ;
}

export default LicenseAnjouan;
