import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/services/brokerage-forex-seychelles/hero-image.png'
import {FaDiamond} from "react-icons/fa6";
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Brokerage And Forex License in Seychelles',
    link: '/brokerage-and-forex-seychelles',
  },
]

function BrokerageForexSeychelles() {
  return (
    <main className='main'>
      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />
        <div className='container'>

          <h1 className='section-title'>Brokerage and Forex license in Seychelles</h1>
          
          <br/>
          <div className='grid-container'>
          <div className='flex-start'>
              <p className='body-pargraph'>
                If you plan to operate in financial markets and offer FX
                brokerage services to clients from regulated jurisdictions, you
                must apply for a Forex broker license, and Seychelles,
                definitely, suits well to get it. This license is required for
                all brokerage firms, mutual and hedge funds.
              </p>
              <h3 className='page-title'>Reasons for choosing an offshore jurisdiction</h3>
              <p className='body-pargraph'>
                Authorities in both offshore and onshore jurisdictions shall
                grand the license to the entity, which fulfills all statutory
                requirements, but as a rule, it is much more easier to adhere to
                offshore legal regime, it is especially important, in terms of
                minimum capital requirements. Below, the basic requirements for
                obtaining Seychelles forex license are displayed.
              </p>
              <h3 className='page-title'>
                Seychelles Financial Services Authority will not grant a license
                unless it is satisfied that the applicant:
              </h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Is a domestic company or a company incorporated under the laws
                  of a recognized jurisdiction;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Has at least 2 (two) natural person appointed as directors,
                  residence requirements are not stipulated;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Has at least 2 (two) shareholders; legal and physical person
                  can act as a shareholder.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Employs at least one licensed Representative of Securities
                  Dealer and a Compliance officer. The directors can occupy this
                  position;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Has a minimum paid‐up capital of US$ 50,000;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Satisfies the FSA that it is a fit and proper person to be
                  licensed as a dealer;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Provides for a separate account to hold clients’ funds;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Has a local office;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Has Professional Indemnity Cover in place
                </p>
              </li>
            </ul>
          </div>
            <HeroRightImage src={HeroImage}/>
          </div>
         
          <div>
            <h3 className='page-title'>The advantages of a Seychelles forex license:</h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Eligibilities of the license holder: the right to engage in
                  securities trading, such as shares, options, bonds and others,
                  as well as to manage investment portfolio on behalf of a client.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Offshore tax regime.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Seychelles is one of the most popular jurisdiction for the
                  establishment of a brokerage company; all the necessary
                  infrastructure for the establishment of a company and doing
                  business is already present.
                </p>
              </li>
            </ul>
            <br/>
            <h3 className='page-title'>
              Seychelles is one of the best jurisdictions for opening a company
              and obtaining a Forex brokerage license in terms of reasonable
              cost and minimal capital requirements. Prompt timeframes are,
              also, worth mentioning:
            </h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Establishment of a Company – 1 week;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Preparation of all necessary documents to apply for the license
                  – approx.1 month, subject to the specific clients’ needs and the
                  difficulty of a project;
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Review of the application be the FSA – 1 months, period may be
                  extended by the FSA up to 3 months
                </p>
              </li>
            </ul>
            <br/>
            <h3 className='page-title'>Application procedure:</h3>
            <ol className='page-list page-paragraph'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>

                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                    Data collection.
                  </span>
                  <br/>
                  This is the first stage, at which a comprehensive KYC
                  procedure takes its place, the procedure incudes:
                  verification of the identity documents, residence address, the
                  source of funds and the check whether a client possess
                  necessary education, qualifications and experience to engage
                  in this business.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                  Preparation of the application.
                  </span>
                  <br/>
                  At this stage, application forms to be filled and all
                  necessary documents to be collected, this, also, includes
                  drafting of the Articles and Memorandum.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                  Submission of the application.
                  </span>
                  <br/>
                  After all documents collected and the forms filled, we will
                  submit the application to Seychelles FSA. All government fees
                  to be paid at this stage.
                </p>
              </li>

              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>
                  Further support.
                  </span>
                  <br/>
                  Our specialists will be in touch with the FSA and monitor the
                  procedure of application consideration. Required documents.
                </p>
              </li>
            </ol>
            <h3 className='page-subtitle'>
              In accordance with the established requirements, each of the
              directors or officers, shareholders and beneficiaries must provide
              the following documents:
            </h3>
            <ol className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Police Clearance Certificate
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Declaration of no history of Bankruptcy
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Certified Passport Copy
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Original or Certified Copy of Bank Reference Letter
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  2 Certified copies of Professional Reference Letters
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Original or Certified Copy of utility bills
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Resume / CV outlining experience in Forex Brokerage Activities
                  and Qualifications warranting issuance of a license.
                </p>
              </li>
              
               <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Information on who the target audience and market will be.
                </p>
              </li>
            </ol>
            <p >
              All documents must be issued no longer than 3 months prior to the
              submission, certified and, if required, translated into English
              and apostilled.
            </p>
            <p>
              Eternity Law International will provide you with expert advice on
              obtaining a forex broker license in a suitable jurisdiction.{' '}
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default BrokerageForexSeychelles
