import React from 'react';
import HeroImg from '../../../../Assets/images/area/residency-and-passport/hero-img.png'
import PageBanner from '../../../../Assets/images/area/residency-and-passport/banner-img.png'
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs"
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Residency and Passport',
        link: '/residency-passport',
    },
]

function ResidencyPassport () {
    return (
        <main className='main'>
            <section className='banking-license-section section'>
                <BreadCrumbs data={BreadCrumbsData}/>
                <div className='container'>
                    <h1 className='section-title'>
                        Residency and Passport
                    </h1>
                    <div className='licensing-container grid-container'>
                        <div className=''>
                            <p className='page-paragraph'>
                                Whether you're captivated by Italy's cultural heritage, Spain's sunny shores, Cyprus's
                                investment-friendly environment, or Dubai's dynamic market, our expert guidance is
                                designed to turn your residency aspirations into reality. We offer comprehensive
                                services tailored to help you navigate the complex path to residency and citizenship
                                through investment, property acquisition, and legal assistance.
                            </p>

                            <h1 className='page-title'>
                                Benefits of Residency in another country:
                            </h1>

                            <p className='body-paragraph'>
                                Diversified Investment Opportunities: Access to new markets and investment
                                opportunities, including real estate, businesses, and other assets not available in
                                one's home country.
                                Tax Advantages: Potential tax benefits such as lower tax rates, no wealth tax, and
                                incentives for foreign investors, depending on the host country’s tax regime.
                                Enhanced Mobility: Easier travel within the region or bloc (like the EU), often without
                                the need for a visa.
                                Safety and Security: Access to a safe and stable living environment, especially in
                                countries with low crime rates and high standards of governance.
                                Quality of Life Improvements: Access to better healthcare, education, and recreational
                                facilities, contributing to a higher standard of living.
                                Cultural and Lifestyle Experiences: Exposure to new cultures, languages, and
                                communities, enriching personal and family life.
                                Business Expansion: Opportunities to expand business operations into international
                                markets, benefiting from local economic policies and a potentially business-friendly
                                climate.
                                Education and Employment Opportunities: Access for oneself and family members to
                                educational institutions and job markets in the host country.
                                Long-term Residency and Citizenship Options: Potential path to permanent residency or
                                citizenship, which can provide additional rights and benefits.
                                Political Stability: A stable political environment, particularly attractive for those
                                coming from regions experiencing turmoil.
                            </p>

                        </div>

                        <HeroRightImage src={HeroImg} />
                    </div>

                    <BannerPageImg src={PageBanner} />
                    <br/>
                    <div className='flex-start'>

                        <h3 className='page-title'>
                            Italy: A Gateway to Culture and Lifestyle
                        </h3>

                        <p className='page-paragraph'>
                            Dive into Italy's rich history and vibrant lifestyle with our specialized residency
                            programs. Options include the Elective Residence Visa, ideal for those wishing to enjoy
                            Italy's lifestyle without active employment, and the Investor Visa for Entrepreneurs, aimed
                            at attracting business investments. We handle the nuances of Italian bureaucracy, ensuring
                            your smooth transition to life in Italy. Additionally, we can assist in acquiring Italian
                            citizenship through investment or heritage, providing a gateway to European mobility.
                        </p>

                        <h3 className='page-title'>
                        Spain: Embrace the Mediterranean Dream
                        </h3>

                        <p className='page-paragraph'>
                            Experience the warm Mediterranean climate and lively culture of Spain. Our services
                            facilitate the acquisition of residency through the Golden Visa Program, requiring
                            investment in real estate, or the Non-Lucrative Visa, suitable for retirees and individuals
                            with sufficient financial means. We streamline the process, making your journey to Spanish
                            residency straightforward and hassle-free, with opportunities to transition to citizenship
                            after a period of legal residency.
                        </p>

                        <h3 className='page-title'>
                            Cyprus: Invest in the Crossroads of Continents
                        </h3>

                        <p className='page-paragraph'>
                            Cyprus offers attractive residency and citizenship options through its Cyprus Investment
                            Program and Permanent Residence Permit. These programs allow you to invest in real estate or
                            local businesses, paving the way for residency and eventual citizenship, which offers the
                            benefits of an EU passport. Our expertise ensures you navigate Cyprus's regulatory
                            environment effectively, maximizing the investment benefits.
                        </p>

                        <h3 className='page-subtitle'>
                            Dubai: Dynamic Hub for Global Citizens
                        </h3>

                        <p className='page-paragraph'>
                            Dubai presents a wealth of opportunities for investors, entrepreneurs, and professionals
                            through various residency visas, including Investor and Retirement Visas. We guide you
                            through the process of obtaining residency in this vibrant city, where innovation meets
                            luxury. Dubai's residency options also provide a path to long-term residency and tax
                            benefits, enhancing its appeal as a global business hub.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Extended Services Across Jurisdictions:
                        </h3>

                        <ol className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                Residency Programs: We offer detailed guidance on the various residency programs
                                available, such as investor visas, golden visas, and residency by investment. Our team
                                supports clients in meeting eligibility requirements and navigating the complex
                                application processes.
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                Property Investment: We assist clients in identifying and acquiring residential or
                                commercial properties that meet their investment criteria, preferences, and budget.
                                Whether it's luxury villas in Spain, seaside apartments in Cyprus, or commercial
                                properties in Dubai, we provide expert advice and support throughout the property
                                transaction.
                            </li>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                Legal and Financial Assistance: In collaboration with trusted local legal and financial
                                experts, we ensure compliance with all regulations, handle all necessary documentation,
                                and facilitate smooth transactions. This includes assistance with tax planning, legal
                                residency requirements, and investment structuring to maximize your benefits.
                            </li>
                        </ol>
                    </div>

                    <br/>
                    <div className='flex-start'>
                        <h2 className='page-title'>
                            Frequently Asked Questions:
                        </h2>

                        <ol className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-title'>
                                    <strong>
                                        What are the benefits of obtaining a residency visa through investment?
                                    </strong>
                                </p>
                            </li>
                            <li className='page-list-item page-paragraph'>
                                Residency visas through investment typically offer the right to live, work, and study in
                                the host country, along with potential tax advantages and a path to citizenship.
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-title'>
                                    <strong>
                                    Can I acquire citizenship through investment?
                                    </strong>
                                </p>
                            </li>
 
                            <li className='page-list-item page-paragraph'>
                                Yes, many countries offer a route to citizenship through investment, often after a
                                residency period, allowing investors to eventually obtain a passport from the country.
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-title'>
                                    <strong>
                                    What types of properties can I invest in to qualify for residency?
                                    </strong>
                                </p>
                            </li>

                            <li className='page-list-item page-paragraph'>
                            Investment options vary by country but commonly include residential properties,
                                commercial real estate, and sometimes government bonds or local business investments.
                            </li>

                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-title'>
                                    <strong>
                                    How long does it take to obtain residency or citizenship through investment?
                                    </strong>
                                </p>
                            </li>
                            <li className='page-list-item'>
                                <p className='page-list-paragraph page-paragraph'>
                                    The timeline can vary significantly depending on the country and specific program,
                                    ranging from a few months to several years for citizenship.
                                </p>
                            </li>
                        </ol>

                    </div>


                </div>
            </section>


        </main>
    )
        ;
}

export default ResidencyPassport;
