import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/area/assets-managment/hero-img.png'
import PageBanner from '../../../../Assets/images/area/assets-managment/banner-img.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import BannerPageImg from "../../../Constant/Banner Page Img/BannerPageImg";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Asset Management and Due Diligence',
    link: '/asset-protection',
  },
]

function AssetProtection() {
  return (
      <main className='main'>

        <section className=' section  asset-protection-section'>
          <BreadCrumbs data={BreadCrumbsData}/>

          <div className='container'>
            <h1 className='section-title'>Asset Management and Due Diligence</h1>
            <div className='licensing-container grid-container'>
              <div >
                <p className='page-paragraph'>
                  At the outset of any startup project, securing sufficient initial
                  funding is crucial. Most startups don't have the luxury of
                  "birthright" capital or serendipitous financial windfalls, which are
                  rare in reality. To attract significant investors, understanding the
                  process of due diligence-or a project audit is essential.
                </p>
                <p className='page-paragraph'>
                  Due Diligence Explained: Due diligence, which means 'due honesty or
                  a verification of the company's activities, is vital for both the
                  startup and the investor. It involves a detailed assessment of the
                  startup. identifying potential pitfalls and enabling the investor to
                  make an informed decision. For the startup, it's an opportunity to
                  demonstrate its potential and convince investors of the viability
                  and profitability of the project.
                </p>
                <p className='page-paragraph'>
                  The audit results often determine the success of securing
                  investment, as they validate the business project and potential
                  return on investment.
                </p>
                <p className='page-paragraph'>
                  Asset Protection Explained: Asset protection refers to the legal
                  techniques and strategies used to protect assets from potential
                  claims of creditors and litigants. It's an essential safeguard for
                  both the startup and the investors, ensuring that the assets remain
                  secure from unexpected legal challenges and financial disputes.
                </p>
                <p className='page-paragraph'>
                  This process involves a meticulous review of the startup's assets to
                  identify vulnerabilities and implement strategies to mitigate risk.
                  For the startup, it's an opportunity to establish a strong
                  foundation that not only protects its assets but also reassures
                  investors about the security of their investment.
                </p>
                <p className='page-paragraph'>
                  The effectiveness of these asset protection strategies often plays a
                  critical role in the stability and longevity of the company, as they
                  prevent significant losses that can occur from lawsuits or claims.
                </p>
              </div>
              <HeroRightImage src={HeroImage}/>
            </div>

            <BannerPageImg src={PageBanner}/>

            <div className='flex-start'>
              <h3 className='page-title'>
                Critical Considerations for Investors: Investors are particularly
                focused on:
              </h3>
              <ol className='page-list margin-bottom'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    The startup’s competence and team dynamics.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Market demand for the product.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Potential risks and scalability of the project.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Financial metrics like profit/loss per customer.
                  </p>
                </li>
              </ol>
            </div>
            <div className='flex-start'>
              <h3 className='page-title'>
                For existing businesses seeking investment, investors are interested
                in:
              </h3>
              <ol className='page-list margin-bottom'>
                <li className='page-list-item'>
                <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Independent valuation of the business.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Financial performance and forecasts.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    The comprehensiveness of the business plan.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Legal frameworks supporting the transaction.
                  </p>
                </li>
              </ol>
            </div>
            <div className='flex-start'>
              <h3 className='page-title'>
                Due Diligence Process: Typically, due diligence involves:
              </h3>
              <ul className='page-list'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Gathering information on all aspects of the company’s operations and
                    risks.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Presenting the business plan to potential investors.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Engaging with key company personnel.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Analyzing data with a team of experts.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Reviewing and approving the business plan annually.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Discussing identified risks and potential mitigation.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Finalizing the deal.
                  </p>
                </li>
              </ul>
              <p className='page-paragraph'>
                The duration of an audit can range from a month to six months,
                depending on the depth of examination required.
              </p>
            </div>
            
            <div className='flex-start'>
              <h3 className='page-title'>
                For Startups: Startups should:
              </h3>
              <ul className='page-list margin-bottom'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Prepare a detailed business plan.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Choose a marketable name that could become a successful brand.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Clearly outline the project specifics, including a detailed
                    financial model.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Present information transparently, highlighting any potential
                    challenges.
                  </p>
                </li>
              </ul>
              
              <ul className='page-list '>
                <h3 className='page-title'>For Investors: Investors need to:</h3>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Thoroughly assess the viability and profitability of the business.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Evaluate the startup’s industry knowledge and potential for growth.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Consider how they can support the startup in realizing its business
                    goals.
                  </p>
                </li>
              </ul>
              <p className='page-paragraph'>
                Asset Protection and Residency Considerations: Asset protection is a
                crucial aspect of business planning, particularly for startups looking
                to mitigate risks and protect the founders’ and investors’ interests.
                Establishing a company in jurisdictions with favorable legal
                frameworks can enhance asset protection and offer strategic
                advantages, such as tax benefits and privacy.
              </p>
            </div>
            
            <div className='flex-start'>
              <h3 className='page-title'>
                Residency through investment can also provide significant benefits,
                including:
              </h3>
              <ul className='page-list'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Enhanced mobility and access to global markets.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Opportunities for tax optimization.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Improved quality of life and access to better healthcare and
                    education systems.
                  </p>
                </li>
              </ul>
              <p className='page-paragraph'>
                Asset Protection and Residency Considerations: Asset protection is a
                crucial aspect of business planning, particularly for startups looking
                to mitigate risks and protect the founders’ and investors’ interests.
                Establishing a company in jurisdictions with favorable legal
                frameworks can enhance asset protection and offer strategic
                advantages, such as tax benefits and privacy.
              </p>
            </div>
            
            <div className='flex-start'>
              <p className='page-title'>
                Residency through investment can also provide significant benefits,
                including:
              </p>
              <ul className='page-list'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Enhanced mobility and access to global markets.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Opportunities for tax optimization.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Improved quality of life and access to better healthcare and
                    education systems.
                  </p>
                </li>
              </ul>
              <p className='page-paragraph'>
                Investors and startups might also consider residency options as part
                of their strategic planning to leverage benefits like citizenship by
                investment programs, which can offer enhanced travel freedoms and
                business opportunities in other countries.
              </p>
            </div>
            
            <div className='flex-start'>
              <h3 className='page-title'>
                Failure Risks in Transactions: Common causes of failed investments
                include:
              </h3>
              <ul className='page-list'>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Lack of industry knowledge by the investor.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Insufficient time allocated to understand the project's financials.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Dishonesty or lack of transparency from the startup.
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Unanticipated real-world implementation challenges
                  </p>
                </li>
                <li className='page-list-item'>
                  <div className='page-list-icon-box'>
                    <FaDiamond className='list-diamon-icon'/>
                  </div>
                  <p className='page-list-paragraph'>
                    Subjective assessments by the investor based on insufficient
                    knowledge.
                  </p>
                </li>
              </ul>
              <p className='body-pargraph'>
                Due diligence is a critical, bilateral process that benefits both the
                startup seeking investment and the investor. Even if a deal does not
                materialize, the insights gained from the due diligence process are
                valuable for future endeavors.
              </p>
              <p className='body-pargraph'>
                For comprehensive due diligence services for your new or existing
                business, consider consulting with professionals who specialize in
                both startup ecosystems and investor relations, ensuring a thorough
                evaluation and support for potential business ventures.
              </p>
            </div>
          </div>
        </section>
      </main>
  )
}

export default AssetProtection
