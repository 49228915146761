import React from 'react';
import PropTypes from 'prop-types';
import './VectorImage.css';

const VectorImageBox = ({ src, alt, vectorImageTitle }) => {
    return (
        <div className='vector-image-card'>
            <div className="our-service-vector-box">
                <img src={src} alt={alt} className="our-service-vector"/>
            </div>
            <h1 className='our-service-content-title'>
                {vectorImageTitle}
            </h1>
        </div>
    );
};

VectorImageBox.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    vectorImageTitle: PropTypes.string,
};

VectorImageBox.defaultProps = {
    alt: 'vector image',
    vectorImageTitle: '',
};

export default VectorImageBox;
