import React from 'react'
import BreadCrumbs from '../../../BreadCrumbs/BreadCrumbs'
import HeroImage from '../../../../Assets/images/services/gaming-gambling-cyprus/hero-image.png'
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Gaming and Gambling in Cyprus',
    link: '/gaming-and-gambling-cyprus',
  }
]

function GamingGamblingCyprus() {
  return (
    <main className='main'>

      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />

        <div className='container'>
          <h1 className='section-title'>Gaming and Gambling license in Cyprus</h1>

          <div className='grid-container'>
            <div>
              <p className='body-pargraph'>
                Cyprus has made significant strides in the regulation of online
                gambling, aligning itself with other European countries. Prior
                to 2012, online gambling was prohibited in Cyprus, with efforts
                in place to suppress interactive wagering. However, a pivotal
                moment occurred when the country’s parliament enacted the
                Betting Law in 2012, establishing a robust legislative framework
                for obtaining a Cyprus gaming license.
              </p>
              <p className='body-pargraph'>
                The introduction of the Betting Law has brought about notable
                improvements in the online gambling landscape of Cyprus. With a
                clear legal framework in place, operators now have a legitimate
                pathway to acquire a Cyprus gaming license, enabling them to
                operate within the boundaries of the law. This shift has
                instilled a sense of security and trust for both operators and
                players, fostering a more transparent and accountable online
                gambling industry in the country.
              </p>
              <p className='body-pargraph'>
                Tell us how we can be of service and one of our team members
                will contact you.
              </p>
              <p className='body-pargraph'>
                The enactment of the Betting Law not only legalized online
                gambling but also facilitated the implementation of measures to
                protect consumers and promote responsible gambling practices.
                The licensing process ensures that operators meet stringent
                criteria and adhere to regulations aimed at safeguarding
                players’ interests, including provisions for player protection,
                fairness, and security. This commitment to responsible gambling
                helps enhance player confidence and prevents issues such as
                fraudulent activities, underage gambling, and money laundering,
                ensuring a safer and more enjoyable online gambling experience
                for all.
              </p>
            </div>
            <HeroRightImage src={HeroImage}/>
          </div>
          
          <div >
            <h3 className='page-title'>
              Obtaining a gambling license in Cyprus offers numerous benefits
              for operators looking to establish their online gambling business.
              Here are ten key advantages:
            </h3>
            <ul className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Reputable and Well-Regulated Market: Cyprus has a reputable and
                  well-regulated gambling market, ensuring a secure and
                  transparent environment for both operators and players. By
                  obtaining a Cyprus gambling license, you demonstrate your
                  commitment to operating within the boundaries of a regulated
                  industry. This helps build trust and credibility with your
                  customers, assuring them that their funds and personal
                  information are protected. Operating in a regulated market also
                  helps you differentiate your business from unlicensed
                  competitors, attracting players who prioritize safety and
                  fairness in their online gambling experience.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Access to the European Union Market: Cyprus is a member of the
                  European Union (EU), which opens doors to a vast market of
                  potential customers. With a Cyprus gambling license, you gain
                  the opportunity to provide online gambling services not only
                  within Cyprus but also in other EU member states. This access to
                  the European market expands your reach and customer base,
                  allowing for greater growth and revenue potential. Furthermore,
                  the EU framework provides a solid legal foundation, ensuring
                  compliance with high standards of consumer protection and
                  responsible gambling practices.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Tax Advantages: Cyprus offers a favorable tax regime for
                  gambling operators. The country provides competitive tax rates
                  and incentives, enabling operators to optimize their financial
                  performance. Corporate tax rates in Cyprus are relatively low
                  compared to many other jurisdictions, resulting in potential tax
                  savings and increased profitability for your gambling business.
                  Additionally, Cyprus has an extensive network of double tax
                  treaties with various countries, reducing the risk of double
                  taxation and facilitating international business operations.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Efficient Licensing Process: The licensing process in Cyprus is
                  known for its efficiency and relatively streamlined procedures.
                  The Cyprus Gaming and Casino Supervision Commission oversees the
                  licensing and regulation of gambling activities, ensuring a
                  transparent and straightforward application process. With proper
                  preparation and adherence to regulatory requirements, you can
                  obtain a Cyprus gambling license within a reasonable timeframe,
                  allowing you to start operating your online gambling business
                  promptly.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Wide Range of Gambling Activities: The Cyprus gambling license
                  covers a wide range of gambling activities, including online
                  casinos, sports betting, poker rooms, and more. This flexibility
                  allows you to diversify your offerings and cater to various
                  preferences of players. Whether you want to focus on a specific
                  type of gambling or offer a comprehensive online gaming
                  platform, the Cyprus license provides the necessary framework to
                  accommodate your business model.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Player Protection and Responsible Gambling: Cyprus has robust
                  regulations in place to ensure player protection and promote
                  responsible gambling practices. By obtaining a Cyprus gambling
                  license, you align your business with these important
                  principles. This not only safeguards the interests of your
                  customers but also enhances your reputation as a responsible and
                  trustworthy operator. Implementing measures such as age
                  verification, self-exclusion options, and responsible gambling
                  tools helps create a safe and enjoyable online gambling
                  experience.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Strong Intellectual Property Protection: Cyprus provides strong
                  intellectual property protection, safeguarding your brand,
                  trademarks, and proprietary software. This protection helps
                  prevent unauthorized use or infringement of your intellectual
                  property, ensuring that your business assets are safeguarded.
                  This aspect is particularly important in the online gambling
                  industry, where intellectual property plays a significant role
                  in establishing a distinctive brand identity and attracting
                  players.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  High-Quality Infrastructure: Cyprus boasts a high-quality
                  infrastructure, including advanced telecommunications networks
                  and reliable internet connectivity. This infrastructure is
                  essential for running a successful online gambling business,
                  ensuring seamless gameplay, fast transactions, and reliable
                  customer support. With Cyprus’ modern infrastructure, you can
                  provide a smooth and uninterrupted online gambling experience
                  for your players, enhancing their satisfaction and engagement.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Skilled Workforce and Professional Service Providers: Cyprus has
                  a skilled workforce and a pool of professional service providers
                  with expertise in the gambling industry. Whether you need
                  assistance with legal and compliance matters, technical support,
                  marketing, or financial services, you can access a network of
                  experienced professionals who understand the unique requirements
                  of the online gambling sector. This availability of specialized
                  expertise enables you to access comprehensive support and advice
                  to enhance your business operations.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Business-friendly Environment: Cyprus offers a business-friendly
                  environment, with a strong legal framework, effective
                  governance, and a robust financial system. The country
                  encourages foreign investment and entrepreneurship, providing
                  incentives and support for businesses to thrive. Cyprus has a
                  stable political climate, economic growth, and a favorable
                  geographical location between Europe, Africa, and the Middle
                  East. These factors contribute to the overall appeal of Cyprus
                  as a jurisdiction for online gambling, making it an attractive
                  choice for operators looking for a conducive business
                  environment.
                </p>
              </li>
            </ul>
            <p className='body-pargraph'>
              In summary, obtaining a gambling license in Cyprus provides
              operators with a host of benefits, including operating in a
              reputable and well-regulated market, accessing the European Union
              market, tax advantages, an efficient licensing process, a wide
              range of gambling activities, player protection and responsible
              gambling practices, intellectual property protection, high-quality
              infrastructure, a skilled workforce and professional service
              providers, and a business-friendly environment. These advantages
              create a solid foundation for your online gambling business,
              ensuring compliance, growth, and long-term success in the dynamic
              and competitive online gambling industry.
            </p>
            <h3 className='page-title'>License Types</h3>
            <p className='body-pargraph'>
              <strong className='page-subtitle'>“The Betting Law”</strong> in Cyprus introduces two distinct types of
              licenses for gambling activities:
            </p>
            <ol className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>Class A License:</span>
                  This license is specifically for bookmakers
                  operating offline sports betting services. It is applicable to
                  bookmakers who have physical offices and excludes services
                  related to horse racing or other activities covered under the
                  Class B license.
                </p>
              </li>
              <li className='page-list-item'>
              <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  <span className='page-list-title'>Class B License:</span>
                  This license covers online gambling activities,
                  excluding slot machines and casino lotteries. It is designed for
                  operators engaged in various online gambling services, such as
                  sports betting, virtual gaming, and other forms of online
                  wagering.
                </p>
              </li>
            </ol>
            <p>
              By implementing these two classes of licenses, “The Betting
              Law” in Cyprus ensures a comprehensive regulatory framework that
              distinguishes between offline and online gambling activities. This
              allows for proper oversight and control over the gambling
              industry, ensuring that operators comply with the specific
              regulations applicable to their respective license class.
            </p>
            <br/>
            <h3 className='page-title'>Licensing Process</h3>
            <p className='body-pargraph'>
              In order to obtain either an A or B license in Cyprus, companies
              must fulfill the following requirements:
            </p>
            <ol className='page-list'>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Cyprus Stocks Availability: The company must have stocks
                  available in Cyprus, indicating a tangible presence in the
                  country.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Foreign Registration: It is required to provide evidence of
                  foreign registration, indicating the existence of a company with
                  a primary activity in the field of betting. Alternatively, a
                  partnership with a local company is also permissible. The
                  minimum share capital requirement for both foreign and local
                  businesses is EUR 500 000.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Division in Cyprus: The company must establish a division or
                  branch in Cyprus that focuses on betting activities. This can be
                  either a foreign company or a local one.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Financial Guarantee: A bank warranty of at least EUR 550 000,
                  from either a Cyprus or another EU bank, is necessary. The
                  warranty should be valid for at least 6 months after obtaining
                  the license.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                Sufficient Resources: The company must demonstrate sufficient
                financial resources to ensure timely payments to players.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Acceptable Accounting System: It is essential to have an
                  acceptable accounting system in place for the chosen betting
                  service, ensuring accurate financial reporting.
                </p>
              </li>
              <li className='page-list-item'>
                <div className='page-list-icon-box'>
                  <FaDiamond className='list-diamon-icon'/>
                </div>
                <p className='page-list-paragraph'>
                  Compliance with the Betting Law of 2012: The company must comply
                  with the provisions of the “Betting Law of 2012,” particularly
                  regarding player protection measures.
                </p>
              </li>
            </ol>
            <p className='page-paragraph'>
              Meeting these requirements is crucial for companies seeking to
              obtain an A or B license in Cyprus. By adhering to these
              regulations, businesses can demonstrate their commitment to
              operating within the legal framework and ensuring a secure and
              responsible gambling environment for players.
            </p>
            <h3 className='page-title'>Licensing Fees</h3>
            <p className='page-paragraph'>
              The license issued for gambling activities in Cyprus has a
              validity period of either 1 or 2 years. After the expiration of
              the license, it is possible to apply for a renewal. However, it’s
              important to note that the National Betting Agency reserves the
              right to revoke the license if the licensee violates any
              provisions of the current law, as stated in Article 24.
            </p>
            <p className='page-paragraph'>
              To obtain the license, the applicant must pay a fee which varies
              depending on the duration of the license. For a 1-year license,
              the fee is EUR 30 000, while for a 2-year license, it is EUR 45
              000.
            </p>
            <p className='page-paragraph'>
              It’s worth noting that for Class B licenses, the payment must be
              made through a debit card, credit card, or any other electronic
              payment method. Cash payment is strictly prohibited according to
              Article 58(1).
            </p>
            <p className='page-paragraph'>
              Once the Class B license is obtained, the licensee is required to
              open an account with a Cyprus bank or any other institution
              authorized to provide banking services in Cyprus. Additionally,
              Class B licensees are obligated to analyze the player’s current
              account before accepting their bet. If there are insufficient
              funds in the player’s account to cover the bet, the licensee must
              reject the bet in accordance with the regulations.
            </p>
            <h3 className='page-title'>Gambling Taxation</h3>
            <p className='page-paragraph'>
              According to Article 71, the applicable tax rate for gambling
              activities in Cyprus is a combined rate of 13%. This rate is
              calculated based on the net revenue generated from the gambling
              operations.
            </p>
            <p className='page-paragraph'>
              Out of the total tax rate, 10% of the net revenue is assigned to
              the Cyprus sports federations. This allocation aims to support and
              promote sports activities within the country.
            </p>
            <p className='page-paragraph'>
              Additionally, 2% of the net revenue is designated for funding
              extra gambling programs. These programs focus on initiatives
              related to responsible gambling, player protection, and the
              prevention of gambling-related harm.
            </p>
            <p className='page-paragraph'>
              In summary, the tax structure for gambling activities in Cyprus
              entails a 10% contribution to sports federations, 2% for
              additional gambling programs, and the remaining 1% forms the tax
              on net revenue. This framework ensures that a portion of the
              generated revenue is directed towards supporting sports
              development and implementing measures to ensure a safe and
              responsible gambling environment.
            </p>
            <h3 className='page-title'>Technical Requirements</h3>
            <p className='page-paragraph'>
              As per the regulations, it is mandatory for all data related to
              gambling activities to be stored solely on a Cyprus server data
              system. This ensures that sensitive information remains within the
              country and is subject to local jurisdiction. The server or data
              center used for this purpose must be connected to the government’s
              electronic system, facilitating the transmission of transactional
              data in a secure manner.
            </p>
            <p className='page-paragraph'>
              Furthermore, websites accepting bets from players are required to
              have a specific address format. The website address should end
              with “LTD” followed by the domain extension “.com.cy”. This
              requirement aims to clearly identify and distinguish licensed
              gambling platforms operating within Cyprus, enabling players to
              easily recognize legitimate and authorized websites.
            </p>
            <p className='page-paragraph'>
              By implementing these regulations, the government of Cyprus aims
              to maintain control over data security and ensure that licensed
              gambling operators adhere to the established standards. The use of
              a Cyprus server data system and the prescribed website address
              format contribute to the overall integrity and transparency of the
              online gambling industry in the country.
            </p>
          </div>

        </div>
      </section>
    </main>
  )
}

export default GamingGamblingCyprus
