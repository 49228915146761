import React from "react";
import './OurServices.css'
import VectorOne from "../../../Assets/images/our-services/vector-1.png";
import VectorTwo from "../../../Assets/images/our-services/vector-2.png";
import VectorThree from "../../../Assets/images/our-services/vector-3.png";
import VectorFour from "../../../Assets/images/our-services/vector-4.png";
import VectorFive from "../../../Assets/images/our-services/vector-5.png";
import VectorSix from "../../../Assets/images/our-services/vector-6.png";

function OurServices () {
    return (
        <div className='wrapper'>
            <div className='our-service-container container '>
                <div className='home-services-info '>
                    <h1 className='our-service-title section-subtitle '>Discover Our Service</h1>
                    <p className='our-service-description'>
                        The international company Offshore Advisory International provides professional services in the
                        field <br/>
                        of international consulting, auditing services, legal and tax services.
                    </p>
                </div>

                <div className='our-service-content'>
                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorOne} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <h1 className='our-service-content-title'>
                            Ready-made companies for sale
                        </h1>
                        <p className='our-service-card-description'>
                            Companies with a bank account around the world
                        </p>
                    </div>

                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorTwo} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <div className='hs-column-content'>
                            <h1 className='our-service-content-title'>
                                Banks for sale
                            </h1>
                            <p className='our-service-card-description'>
                                Established Banks for sale
                            </p>
                        </div>
                    </div>

                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorThree} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <div className='hs-column-content'>
                            <h1 className='our-service-content-title'>
                                Ready-made licenses for sale
                            </h1>
                            <p className='our-service-card-description'>
                                Forex, cryptocurrencies, gambling,<br/> EMI, PSP, SVF, MSO, DLT
                            </p>
                        </div>
                    </div>

                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorFour} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <div className='hs-column-content'>
                            <h1 className='our-service-content-title'>
                                Licensing
                            </h1>
                            <p className='our-service-card-description'>
                                Forex licenses, cryptocurrencies, gambling,<br/> EMI, PSP, SVF, MSO, DLT
                            </p>
                        </div>
                    </div>

                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorFive} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <div className='hs-column-content'>
                            <h1 className='our-service-content-title'>
                                Offshore company registration
                            </h1>
                            <p className='our-service-card-description'>
                                Expert advice on the acquisition of an offshore company
                            </p>
                        </div>
                    </div>

                    <div className='our-service-card'>
                        <div className='our-service-vector-box'>
                            <img src={VectorSix} alt='service-vector' className='our-service-vector'/>
                        </div>
                        <div className='hs-column-content'>
                            <h1 className='our-service-content-title'>
                                Open a bank account
                            </h1>
                            <p className='our-service-card-description'>
                                Open bank accounts in more than 120 banks around the world
                            </p>
                        </div>
                    </div>
                </div>

                <div className='our-service-list-container'>
                    <ul className='our-service-list'>
                        <div className='our-service-list-card'>
                            <h3 className='our-service-list_title'>
                                Other Practices
                            </h3>
                            <li className="our-service-list-item">Accounting services</li>
                            <li className="our-service-list-item">Attorney at law</li>
                            <li className="our-service-list-item">Corporate services</li>
                            <li className="our-service-list-item">Drafting contracts</li>
                            <li className="our-service-list-item">FinTech Services</li>
                            <li className="our-service-list-item">Forex trading license</li>
                            <li className="our-service-list-item">ICO consulting services</li>
                            <li className="our-service-list-item">Investment</li>
                            <li className="our-service-list-item">Opening an account in the payment system</li>
                            <li className="our-service-list-item">Certificates of good standing, incumbency</li>
                            <li className="our-service-list-item">Registration of trademarks</li>
                            <li className="our-service-list-item">Tax consulting services</li>
                            <li className="our-service-list-item">Valuation and business-modeling</li>
                        </div>
                        <div className='our-service-list-card'>
                            <li className="our-service-list-item">Opening a cryptocurrency debit card</li>
                            <li className="our-service-list-item">Citizenship</li>
                            <li className="our-service-list-item">Ready-made cryptocurrency licenses for sale</li>
                            <li className="our-service-list-item">Due diligence</li>
                            <li className="our-service-list-item">Fintech Software Development</li>
                            <li className="our-service-list-item">Gambling</li>
                            <li className="our-service-list-item">International business certification</li>
                            <li className="our-service-list-item">Investment in real estate</li>
                            <li className="our-service-list-item">Legal Opinion</li>
                            <li className="our-service-list-item">Opening merchant account</li>
                            <li className="our-service-list-item">Redomicile of companies</li>
                            <li className="our-service-list-item">Support IPO</li>
                            <li className="our-service-list-item">Trust registration</li>
                        </div>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default OurServices;