import React from 'react';
import HeroImage from '../../../../Assets/images/services/banking-license-in-western-sahara/hero-image.png'
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import HeroRightImage from "../../../Constant/HeroRightImage/HeroRightImage";
import {FaDiamond} from "react-icons/fa6";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Banking License In western Sahara',
        link: '/license-western-sahara',
    },
]

function LicenseWesternSahara () {
    return (
        <main className='main'>
            <section
                className='banking-license-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>
                <div className='banking-license-container container' >

                    <h1 className='section-title'>Banking License in Western Sahara </h1>

                    <div className='licensing-container grid-container '>
                    <div className='flex-start'>
                            <p className='body-pargraph'>
                                The main advantage of obtaining an offshore bank license is unlimited financial freedom and safety, as well as an opportunity to increase the income through offshore companies.
                                An offshore bank licensee cannot carry on banking business locally, only outside of SADR.
                            </p>
                            <h2 className='page-subtitle'>
                                Offshore Advisory offers two basic types of the offshore banking licenses:
                            </h2>
                            <p className='body-pargraph'>
                                Class I International Banking License <br/>
                                Are you ready to take your banking institution to new heights? A Class I International Banking License is your gateway to a world of financial opportunities. This prestigious license ensures that your institution complies with the highest standards of excellence and offers unrivaled financial services to a global clientele.
                            </p>
                        </div>

                        <HeroRightImage src={HeroImage} />
                    </div>


                    <div className='flex-start'>
                        <h2 className='page-subtitle'>
                            Capital Requirements - Setting the Gold Standard
                        </h2>
                        <p className='body-pargraph'>
                            To hold a Class I International Banking License, your institution is required to maintain a
                            minimum capital, as periodically determined by the esteemed regulatory authority, the CRA of
                            Western Sahara. This minimum capital shall not fall below a robust three million United
                            States dollars in paid-up capital. Out of this total, an impressive five hundred thousand
                            United States dollars must be securely deposited with the CRA of Western Sahara or in a
                            manner approved by the CRA.
                        </p>
                        <h2 className='page-title'>
                            Benefits of a Class I License:
                        </h2>

                        <ol className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Global Prestige: Your institution gains international recognition and trust, reinforcing
                                    your position as a reputable and trustworthy financial institution.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Financial Stability: A substantial paid-up capital ensures your institution's financial
                                    strength and resilience, allowing you to withstand market fluctuations.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Access to International Markets: The Class I International Banking License grants you
                                    the privilege to tap into global markets, attracting clients from all corners of the
                                    world.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Compliance with Best Practices: By adhering to the regulatory standards set by the CRA
                                    of Western Sahara, you demonstrate your commitment to best practices and ethical
                                    conduct.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Confidence and Trust: With a Class I License, your clients can trust in the robustness
                                    and stability of your institution, strengthening your client relationships and
                                    attracting new clients.
                                </p>
                            </li>
                        </ol>

                    </div>


                    <div className='flex-start'>
                        <h3 className='page-title'>
                            Unlock Opportunities with a Class II International Banking License
                        </h3>

                        <p className='body-pargraph'>
                            When it comes to expanding your financial horizons and engaging in international banking
                            activities, a Class II International Banking License is the key to a world of possibilities. This license empowers your institution to conduct international banking services for a select group of clients, meticulously approved by the prestigious Capital Regulatory Authority (CRA) of Western Sahara.
                        </p>
                        <p>
                            What sets the Class II International Banking License apart is its tailored approach to clientele. The CRA of Western Sahara periodically curates a list of eligible customers or provides specific criteria as part of the licensing terms. This personalized approach ensures that your institution is targeting the right clients for your unique financial services.
                        </p>
                        <p>
                            To maintain a Class II International Banking License, your institution is required to uphold a minimum capital level, as determined by the CRA of Western Sahara. This capital commitment is flexible and may be adjusted over time to meet the evolving needs of the financial industry.
                        </p>
                    </div>
                    <div className='flex-start'>
                        <h3 className='page-title'>
                            With a Class II International Banking License, you'll have the opportunity to:
                        </h3>
                        <ul className='page-list'>
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Engage with a curated list of clients for focused and efficient service.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Stay compliant with evolving regulatory standards, thanks to the CRA's flexible capital
                                    requirements.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Demonstrate financial stability and trustworthiness through a significant deposit.
                                </p>
                            </li>
                            
                            <li className='page-list-item'>
                                <div className='page-list-icon-box'>
                                    <FaDiamond className='list-diamon-icon'/>
                                </div>
                                <p className='page-list-paragraph'>
                                    Expand your institution's international banking portfolio with confidence.
                                </p>
                            </li>
                        </ul>

                        <p className='body-pargraph'>
                            This license not only opens doors to global financial markets but also demonstrates your
                            institution's dedication to excellence and integrity in international banking.
                        </p>

                        <p className='body-pargraph'>
                            Ready to embark on this journey and elevate your institution's international banking status?
                            The Class II International Banking License is your gateway to success. Don't miss out on this exclusive opportunity to thrive in the ever-evolving world of international finance.
                        </p>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default LicenseWesternSahara;
