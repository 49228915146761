import React from 'react';
import './SpecialOffer.css'
import { IoRemoveOutline } from "react-icons/io5";

function SpecialOffer() {
    return (
        <section className='section'>
            <div className='offer-container container'>
                <div className="offer-card">
                    <div className="offer-blob"></div>
                    <div className="offer-content">
                        <h1 className='offer-title'>Western Sahara</h1>
                        <p className='offer-description'>
                            Obtain gaming license in Western Sahara with only <strong>5,000.00 USD</strong>
                        </p>
                    </div>
                </div>
            </div>
        </section>);
}

export default SpecialOffer;
