import React, {useState, useEffect, useCallback, useRef} from 'react';
import {RiMenuLine, RiCloseLine, RiArrowDownSLine, RiArrowUpSLine} from 'react-icons/ri';
import './Header.css';
import HeaderLogo from '../../Assets/images/Header/logo.png';
import {Link} from 'react-router-dom';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isToggleClicked, setIsToggleClicked] = useState(false);
    const headerRef = useRef(null);

    const toggleMenu = useCallback(() => {
        setIsToggleClicked(true);
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    }, []);

    const toggleDropdown = useCallback((key) => {
        setOpenDropdown(prevOpenDropdown => (prevOpenDropdown === key ? null : key));
    }, []);

    const closeDropdownsAndMenu = useCallback(() => {
        setOpenDropdown(null);
        setMenuOpen(false);
    }, []);

    const scrollToTopAndCloseMenu = () => {
        window.scrollTo(0, 0);
        closeDropdownsAndMenu();
    };

    const handleClickOutside = useCallback((event) => {
        if (isToggleClicked) {
            setIsToggleClicked(false);
            return;
        }
        if (headerRef.current && !headerRef.current.contains(event.target)) {
            closeDropdownsAndMenu();
        }
    }, [closeDropdownsAndMenu, isToggleClicked]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1118) {
                setMenuOpen(false);
                setOpenDropdown(null);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    const renderDropdownArrow = (isOpen) => {
        return isOpen ?
            <RiArrowDownSLine className='dropdown-arrow'/>
            :
            <RiArrowUpSLine className='dropdown-arrow'/>
    };

    return (
        <header className="header" ref={headerRef}>
            <nav className="nav container">
                <div className="nav__data">
                    <Link to='/' className="nav__logo" onClick={scrollToTopAndCloseMenu}>
                        <img src={HeaderLogo} alt='header logo' className='header-logo-img'/> Offshore Advisory
                    </Link>

                    <div className="nav-toggle" onClick={toggleMenu}>
                        {menuOpen ? <RiCloseLine className="nav-toggle-close"/> :
                            <RiMenuLine className="nav-toggle-menu"/>}
                    </div>
                </div>

                <div className={`nav-menu  ${menuOpen ? 'show-menu' : ''}`}>
                    <ul className="nav-list ">
                        <li className={`dropdown__item ${openDropdown === "aboutcompany" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown__button"
                                onClick={() => toggleDropdown("aboutcompany")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => toggleDropdown("aboutcompany")}
                            >
                                About Company {renderDropdownArrow(openDropdown === "aboutcompany")}
                            </div>
                            <div
                                className={`dropdown-container ${openDropdown === "aboutcompany" ? 'dropdown-open' : ''}`}
                            >
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <ul className="dropdown__list">
                                            <li><Link to="/about" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>About the Company</Link></li>
                                            <li><Link to="/faq" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>FAQ</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={`dropdown__item ${openDropdown === "areas" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown__button"
                                onClick={() => toggleDropdown("areas")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => toggleDropdown("areas")}
                            >
                                Areas {renderDropdownArrow(openDropdown === "areas")}
                            </div>
                            <div className={`dropdown-container ${openDropdown === "areas" ? 'dropdown-open' : ''}`}>
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <ul className="dropdown__list">
                                            <li><Link to="/offshore-company-registration" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Offshore Company
                                                Registration</Link></li>
                                            <li><Link to="/license" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Licensing</Link></li>
                                            <li><Link to="/opening-bank-accounts" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Opening bank accounts</Link>
                                            </li>
                                            <li><Link to="/residency-passport" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Residency and Passport</Link>
                                            </li>
                                            <li><Link to="/asset-protection" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Asset Management and Due
                                                Diligence</Link></li>
                                            <li><Link to="/non-dom-tax-regime" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Non Dom Tax Regime</Link></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className={`dropdown__item ${openDropdown === "services" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown__button"
                                onClick={() => toggleDropdown("services")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => toggleDropdown("services")}
                            >
                                Services {renderDropdownArrow(openDropdown === "services")}
                            </div>
                            <div
                                className={`dropdown-container ${openDropdown === "services" ? 'dropdown-open' : ''}`}>
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <span className="dropdown__title">Banking Licensing</span>
                                        <ul className="dropdown__list">
                                            <li><Link to='/banking-license' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Banking License</Link></li>
                                            <li><Link to='/license-western-sahara' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Banking License in Western
                                                Sahara</Link></li>
                                            <li><Link to='/license-mwali' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Banking License in Mwali</Link>
                                            </li>
                                            <li><Link to='/license-anjouan' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Banking License in
                                                Anjouan</Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <span className="dropdown__title">Brokerage</span>
                                        <ul className="dropdown__list">
                                            <li><Link to='/brokerage-forex-license' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex
                                                license</Link></li>
                                            <li><Link to='/brokerage-forex-western-sahara' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex license in
                                                Western Sahara</Link></li>
                                            <li><Link to='/brokerage-forex-mwali' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex in
                                                Mwali</Link></li>
                                            <li><Link to='/brokerage-forex-anjouan' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex in
                                                Anjouan</Link></li>
                                            <li><Link to='/brokerage-forex-cyprus' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex in
                                                Cyprus</Link></li>
                                            <li><Link to='/brokerage-forex-seychelles' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Brokerage and Forex in
                                                Seychelles</Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <span className="dropdown__title">Gaming</span>
                                        <ul className="dropdown__list">
                                            <li><Link to='/gaming-license' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Gaming and Gambling
                                                license</Link></li>
                                            <li><Link to='/gaming-license-western-sahara' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Gaming and Gambling license in
                                                Western Sahara</Link></li>
                                            <li><Link to='/gaming-license-anjouan' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Gaming and Gambling license in
                                                Anjouan</Link></li>
                                            <li><Link to='/gaming-license-cyprus' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Gaming and Gambling license in
                                                Cyprus</Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown__group">
                                        <ul className="dropdown__list">
                                            <li><Link to="/offshore-company-registration" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Offshore Company
                                                Registration</Link></li>
                                            <li><Link to="/residency-passport" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Residency and Passport</Link>
                                            </li>
                                            <li><Link to="/opening-bank-accounts" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Opening bank accounts</Link>
                                            </li>
                                            <li><Link to="/asset-protection" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Asset Management and Due
                                                Diligence</Link></li>
                                            <li><Link to="/non-dom-tax-regime" className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Non Dom Tax Regime</Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={`dropdown__item ${openDropdown === "jurisdictions" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown__button"
                                onClick={() => toggleDropdown("jurisdictions")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => toggleDropdown("jurisdictions")}
                            >
                                Jurisdictions {renderDropdownArrow(openDropdown === "jurisdictions")}
                            </div>
                            <div
                                className={`dropdown-container ${openDropdown === "jurisdictions" ? 'dropdown-open' : ''}`}>
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <ul className="dropdown__list">
                                            <li><Link to='/western-sahara' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Western Sahara</Link></li>
                                            <li><Link to='/mwali-autonomous' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Mwali Autonomous</Link></li>
                                            <li><Link to='/anjouan' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Anjouan</Link></li>
                                            <li><Link to='/uae' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>UAE</Link></li>
                                            <li><Link to='/cyprus' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Cyprus</Link></li>
                                            <li><Link to='/italy' className="dropdown__link"
                                                      onClick={scrollToTopAndCloseMenu}>Italy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className={`dropdown__item ${openDropdown === "CompanyFormation" ? 'show-dropdown' : ''}`}>
                            <div
                                className="nav-link dropdown__button"
                                onClick={() => toggleDropdown("CompanyFormation")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => toggleDropdown("CompanyFormation")}
                            >
                                Company Formation {renderDropdownArrow(openDropdown === "CompanyFormation")}
                            </div>
                            <div
                                className={`dropdown-container ${openDropdown === "CompanyFormation" ? 'dropdown-open' : ''}`}>
                                <div className="dropdown__content">
                                    <div className="dropdown__group">
                                        <ul className="dropdown__list">
                                            <li>
                                                <Link
                                                    to='/anajouan-formation'
                                                    className="dropdown__link"
                                                    onClick={scrollToTopAndCloseMenu}>
                                                    Anjouan Offshore Company Formation
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/western-sahra-formation'
                                                    className="dropdown__link"
                                                    onClick={scrollToTopAndCloseMenu}
                                                >
                                                    Western Sahara Offshore Company Formation
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/cyprus-formation'
                                                    className="dropdown__link"
                                                    onClick={scrollToTopAndCloseMenu}
                                                >
                                                    Cyprus Offshore Company Formation
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to='/mwali-formation'
                                                    className="dropdown__link"
                                                    onClick={scrollToTopAndCloseMenu}
                                                >
                                                    Mwali Offshore Company Formation
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li>
                            <Link to='/contact' className='nav-link' onClick={scrollToTopAndCloseMenu}>
                                Contact us
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
