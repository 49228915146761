import React from 'react';
import './HomeContent.css'

function HomeContent() {
    return (
        <div className='home-content-container  container'>
            <h1 className='section-title home-content-title'>
                Your offshore zone solution
            </h1>

            <p className='home-content-paragraph'>
                Our clients at Offshore Advisory include businesses from a range of industries, specifically IT
                companies, payment and banking entities, gambling organizations, Forex initiatives, and
                cryptocurrency firms.
            </p>
            <p className='home-content-paragraph'>
                Additionally, our clients have the option to select from an extensive array of pre-established
                companies across various commercial sectors, with our experts providing assistance in their
                acquisition.
            </p>
            <p className='home-content-paragraph'>
                Offshore advisory has been an ally for thousands of customers since its inception. We work all
                over the globe, accompanying banking and payment establishments, international organizations
                engaged in trade, exchanges working with cryptocurrencies, digital-technology organizations,
                outsourcing and logistics firms, gambling, IT-structures and many others.
            </p>
            <p className='home-content-paragraph'>
                Our specialists know all the legal nuances and law features of the states they work with, which
                allows us to provide the most effective result for our customers.
            </p>
            <p className='home-content-paragraph'>
                Our clients at Offshore Advisory include businesses from a range of industries, specifically IT
                companies, payment and banking entities, gambling organizations, Forex initiatives, and
                cryptocurrency firms.
            </p>
            <p className='home-content-paragraph'>
                Additionally, our clients have the option to select from an extensive array of pre-established
                companies across various commercial sectors, with our experts providing assistance in their
                acquisition.
            </p>
            <p className='home-content-paragraph'>
                Offshore advisory has been an ally for thousands of customers since its inception. We work all
                over the globe, accompanying banking and payment establishments, international organizations
                engaged in trade, exchanges working with cryptocurrencies, digital-technology organizations,
                outsourcing and logistics firms, gambling, IT-structures and many others.
            </p>
            <p className='home-content-paragraph'>
                Our specialists know all the legal nuances and law features of the states they work with, which
                allows us to provide the most effective result for our customers.
            </p>

            <p className='home-content-paragraph'>
                Offshore Advisory is a premier provider of corporate, trust, fiduciary, and fund services,
                offering specialized solutions for a global clientele. We serve international corporations,
                institutional investors, high-net-worth individuals, families, and intermediaries. Our services
                span over 80 countries, ensuring compliance and efficiency.
            </p>
        </div>
    );
}

export default HomeContent;
