import React from 'react';
import './Hero.css'
import HomeVectorOne from '../../../../Assets/images/Home/home-vector-one.png';

function Hero() {
    return (
        <main className='main hero-main'>
            <section
                className='hero-section '
                style={{
                    backgroundImage: `url(${HomeVectorOne})`,
                }}
            >
                <div className='hero-container container'>
                    <div className="hero-title-box">
                        <h1 className="hero-title">
                            Offshore Advisory Corporate
                            Legal Services Worldwide
                        </h1>
                    </div>
                </div>

            </section>
            <div className='hero-content container'>
                <div className='hero-tel-card'>
                    <div className='hero-tel-box'>
                        <h3>Cyprus</h3>
                        <a href='tel:+35796782975' className='hero-tel-link'>
                            +357 9678 2975
                        </a>
                    </div>
                    <div className='hero-tel-box'>
                        <h3>Albania</h3>
                        <a href='tel:+355693377716' className='hero-tel-link'>
                            +355 69 337 7716
                        </a>
                    </div>
                    <div className='hero-tel-box'>
                        <h3>London</h3>
                        <a href='tel:+442045774328' className='hero-tel-link'>
                            +44 20 4577 4328
                        </a>
                    </div>
                </div>

                <div className='hero-count-box'>
                    <p className='hero-count-card'>
                        <strong className='hero-count-number'>4713</strong><br/>
                        <span className='hero-count-description'>
                            Companies Serviced
                        </span>
                    </p>

                    <p className='hero-count-card'>
                        <strong className='hero-count-number'>541</strong><br/>
                        <span className='hero-count-description'>
                            Licenses Obtained
                        </span>
                    </p>
                </div>
                
            </div>
        </main>
    );
}

export default Hero;
